import React from "react";
import { Link } from "react-router-dom";
import Home2Header from "./header";
import Home2Footer from "./footer";

const PatientMessageSuccess = (props) => {
  return (
    <>
      <Home2Header />
      <div className="content success-page-cont">
        <div className="container-fluid">
          <div className="row justify-content-center">
            {/* Success Card */}
            <div className="card success-card">
              <div className="card-body">
                <div className="success-cont">
                  <i className="fas fa-check" />
                  <h3>Thank you!</h3>
                  {/*<h4>You'll receive the pharmacy details via text and email within the next 10 minutes.</h4>*/}
                  {/*<p className="mb-0">Order ID: REF#{props.match.params.orderRef}</p>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Home2Footer />
    </>
  );
};

export default PatientMessageSuccess;
