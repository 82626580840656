import axios from 'axios';

const api = axios.create({
  baseURL: '/',
});

// Add a request interceptor
api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('prescriptionquest-authorization-token');
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    (error) => Promise.reject(error)
);

export default api