import React, {useEffect, useState} from 'react'
import DoctorSidebar from '../sidebar'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { doctordashboardprofile01, doctordashboardprofile02, doctordashboardprofile06, doctordashboardprofile3 } from '../../imagepath'
import Header from '../../header'
import DoctorFooter from '../../common/doctorFooter'
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import api from '../../../api';
import dayjs from "dayjs";
import {Button, Table, Tag} from "antd";
import {RxDrugDosage, RxDrugName} from "../../common/drug";
import {useHistory} from "react-router-dom";

const DoctorRequest = (props) => {
    const history = useHistory();
    const authUser = useAuthUser();
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        fetchTransactions();
    }, []);

    const fetchTransactions = () => {
        api.get(`/pharmacies/${authUser.pharmacy.id}/rx_search_transactions.json`)
            .then((res) => {
                console.log(res.data)
                if (res.data) {
                    const data = res.data.map((transaction) => {
                        return {
                            key: transaction.id,
                            id: `REF#${10000+transaction.id}`,
                            orderDate: dayjs(transaction.created_at).format('YYYY-MM-DD'),
                            drugName: transaction.rx_drug?.rxcui,
                            dosage: transaction.rx_drug?.rxcui,
                            patient: transaction.patient.first_name + ' ' + transaction.patient.last_name,
                            shipping: transaction.shipping,
                            status: `${transaction.status}`,
                        }
                    });
                    setTransactions(data)
                }
                // setNameOptionsLoading(false);
            })
            .catch(err => console.log(err));
    }

    const columns = [
        {
            title: 'Order ID#',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Order Date',
            dataIndex: 'orderDate',
            key: 'date',
        },
        {
            title: 'Drug Name',
            dataIndex: 'drugName',
            key: 'drugName',
            render: (rxcui) => (<RxDrugName rxcui={rxcui} />),
        },
        {
            title: 'Dosage',
            key: 'dosage',
            dataIndex: 'dosage',
            render: (rxcui) => (<RxDrugDosage rxcui={rxcui} />),
        },
        {
            title: 'Patient',
            key: 'patient',
            dataIndex: 'patient',
        },
        {
            title: 'Shipping',
            key: 'shipping',
            dataIndex: 'shipping',
            render: (shipping, record) => (
                <>
                    <Tag color={`${record.status === 'initiated' ? 'gray' : (shipping === 'delivery' ? 'purple' : 'orange')}`} key={shipping}>
                        {`${record.status === 'initiated' ? '--' : shipping.toUpperCase()}`}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (status) => (
                <>
                    <Tag color={`${status === 'completed' ? 'green-inverse' : (status === 'initiated' ? 'blue-inverse' : 'orange-inverse')}`} key={status}>
                        {`${status === 'completed' ? 'COMPLETED' : (status === 'initiated' ? 'AWAITING PATIENT' : 'READY TO SHIP')}`}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Button onClick={() => history.push('/accounts/pharmacies/rx-transactions/' + record.key)}>
                    View
                </Button>
            ),
        },
    ];

    return (
        <div className="col-lg-8 col-xl-9">
            <div className="dashboard-header">
                <h3>Transactions</h3>
                {/*<ul>*/}
                {/*    <li>*/}
                {/*        <div className="dropdown header-dropdown">*/}
                {/*            <Link*/}
                {/*                className="dropdown-toggle nav-tog"*/}
                {/*                data-bs-toggle="dropdown"*/}
                {/*                to="#"*/}
                {/*            >*/}
                {/*                Last 7 Days*/}
                {/*            </Link>*/}
                {/*            <div className="dropdown-menu dropdown-menu-end">*/}
                {/*                <Link to="#" className="dropdown-item">*/}
                {/*                    Today*/}
                {/*                </Link>*/}
                {/*                <Link to="#" className="dropdown-item">*/}
                {/*                    This Month*/}
                {/*                </Link>*/}
                {/*                <Link to="#" className="dropdown-item">*/}
                {/*                    Last 7 Days*/}
                {/*                </Link>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </li>*/}
                {/*</ul>*/}
            </div>
            <Table columns={columns} dataSource={transactions} />
        </div>
    )
}

export default DoctorRequest
