import ReactOnRails from 'react-on-rails';
// eslint-disable-next-line import/no-webpack-loader-syntax
import 'expose-loader?exposes=$,jQuery!jquery';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "../../app/assets/icons/fontawesome/css/fontawesome.min.css";
import "../../app/assets/icons/fontawesome/css/all.min.css";
import "../../app/assets/icons/feather/css/iconfont.css";
import "../../app/assets/scss/main.scss";

import AppRouter from "../../approuter";

ReactOnRails.setOptions({
  // traceTurbolinks: process.env.TRACE_TURBOLINKS, // eslint-disable-line no-undef
  // process.env.TRACE_TURBOLINKS -> error: development is not defined
  traceTurbolinks: true,
});

ReactOnRails.register({
  AppRouter
});

ReactOnRails.registerStore({
  // appStore,
});
