/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {Link, useHistory} from "react-router-dom";
import { DashboardSidebar } from "./sidebar/sidebar.jsx";

import Footer from "../../footer";
import Header from "../../header.jsx";
import DoctorFooter from "../../common/doctorFooter/index.jsx";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import 'react-circular-progressbar/dist/styles.css';
import api from '../../../api';
import {Avatar, Button} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined, EyeOutlined, UserOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {RxDrugName} from "../../common/drug";

const Dashboard = (props) => {
  const history = useHistory();
  const authUser = useAuthUser();
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    if (authUser.patient) {
      fetchRequests();
    }
  }, []);

  const fetchRequests = () => {
    api.get(`/patients/${authUser.patient.id}/rx_search_requests.json`)
        .then((res) => {
          if (res.data) {
            const data = res.data.filter((order) => order.rx_drug)
            setRequests(data)
            console.log(data)
          }
          // setNameOptionsLoading(false);
        })
        .catch(err => console.log(err));
  }


  const requestColumns = requests.map((request) => (
      <tr key={request.id}>
        <td>
          <div className="patient-info-profile">
            {/*<Link*/}
            {/*    to="/doctor/appointments"*/}
            {/*    className="table-avatar"*/}
            {/*>*/}
            <Avatar icon={<UserOutlined/>}/>
            {/*</Link>*/}
            <div className="patient-name-info">
              <span>{`REQ#${request.id}`}</span>
              <h5>
                {/*<Link to="/doctor/appointments">*/}
                <RxDrugName rxcui={request.rx_drug.rxcui} /> : {request.drug_quantity}
                {/*</Link>*/}
              </h5>
            </div>
          </div>
        </td>
        <td>
          <div className="appointment-date-created">
            <h6>Created: {dayjs(request.created_at).format('MMM D, YYYY h:mm A')}</h6>
            <span className={`badge table-badge badge-${request.rx_search_transaction && request.rx_search_transaction.status === "completed" ? 'success' : (request.rx_search_transaction && request.rx_search_transaction.status === "initiated" ? "warning" :"info" )}`}>
              {`${request.rx_search_transaction && request.rx_search_transaction.status === "completed" ? (request.rx_search_transaction.shipping === "pickup" ? 'Ready for Pick up' : "Shipped") : (request.rx_search_transaction && request.rx_search_transaction.status === "initiated" ? "Accepted" : "Processing" )}`.toUpperCase()}
            </span>
          </div>
        </td>
        <td>
          {
            request.rx_search_orders && request.rx_search_orders.findIndex((order) => order.rx_search_transaction && order.accepted) >= 0 &&
            <Button type="default" size="small" icon={<EyeOutlined/>}
                    onClick={() => history.push('/accounts/patients/rx-transactions/' + request.rx_search_orders.filter((order) => order.rx_search_transaction && order.accepted)[0].rx_search_transaction.id)}>
              View
            </Button>
          }
        </td>
      </tr>
  ))

  return (
    <div className="col-lg-8 col-xl-9">
      <div className="dashboard-header">
        <h3>Rx Orders</h3>
      </div>
      <div className="row">
        <div className="dashboard-card-body">
          <div className="table-responsive">
            <table className="table dashboard-table">
              <tbody>
              {requestColumns}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
