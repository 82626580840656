import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import { Link } from "react-router-dom";
import {
  SolutionOutlined,
  UserOutlined,
  HomeOutlined,
  FileDoneOutlined,
  CloudUploadOutlined,
  InboxOutlined,
  LoadingOutlined,
  CheckOutlined,
  EditOutlined,
  LeftOutlined,
  RightOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {Steps, ConfigProvider, Form, Input, DatePicker, Button, Upload, Descriptions, Divider, Checkbox, Modal, Alert} from 'antd';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Home2Header from "./header";
import Home2Footer from "./footer";
import axios from "axios";
import api from '../../../api';
import dayjs from 'dayjs';
import Header from "../../header";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const RxRequest = (props) => {
  const authUser = useAuthUser();
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();
  const [rxRequests, setRxRequests] = useState([]);

  const [form0] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();

  const [step, setStep] = useState(0);
  const [medication, setMedication] = useState({});
  const [formData, setFormData] = useState({});
  const [requestSubmitting, setRequestSubmitting] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isConsentModalOpen, setIsConsentModalOpen] = useState(false);
  const [errors, setErrors] = useState(null);

  const [insuranceUploadUrl, setInsuranceUploadUrl] = useState(null);
  const [couponUploadUrl, setCouponUploadUrl] = useState(null);

  console.log(authUser)
  const onStepChange = (value) => {
    if (value < step) {
      setStep(value);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const showTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const handleTermsModalOk = () => {
    setIsTermsModalOpen(false);
  };

  const showConsentModal = () => {
    setIsConsentModalOpen(true);
  };

  const handleConsentModalOk = () => {
    setIsConsentModalOpen(false);
  };

  const fetchRxRequests = () => {
    api.get(`/patients/${authUser.patient.id}/rx_search_requests.json`)
        .then((res) => {
          if (res.data) {
            const data = res.data.filter((order) => order.rx_drug)
            setRxRequests(data)
            console.log(data)
          }
          // setNameOptionsLoading(false);
        })
        .catch(err => console.log(err));
  }

  useEffect(() => {
    updateOptions(props.match.params.rxcui);
    if (authUser && authUser.patient) {
      fetchRxRequests();
    }
  }, []);

  const updateOptions = async (rxcui) => {
    console.log(dayjs().subtract(18, 'year').month(12).date(31))
    const resp = await axios.get('https://rxnav.nlm.nih.gov/REST/RxTerms/rxcui/' + rxcui + '/allinfo.json')

    if (resp.data.rxtermsProperties) {
      setMedication(resp.data.rxtermsProperties);
      console.log(resp.data.rxtermsProperties);
    }
  };

  const onFinishStep0 = (values) => {
    console.log(values);
    setFormData({...formData, ...values})
    setStep(1);
  };
  const onFinishStep1 = (values) => {
    console.log(values);
    setFormData({...formData, ...values})
    setStep(2);
  };
  const onFinishStep2 = (values) => {
    console.log(values);
    setFormData({...formData, ...values})
    setStep(3);
  };
  const onFinishStep3 = (values) => {
    console.log(values);
    setFormData({...formData, ...values})
    setStep(4);
  };
  const onFinishStep4 = (values) => {
    console.log({...formData, ...values})

    const postData = {...formData, ...values,
      user: authUser && authUser.patient ? authUser.id : null,
      pharmacies: props.match.params.pharmacies,
      birth_date: dayjs(formData.birth_date).format('YYYY-MM-DD'),
      drug_rxcui: props.match.params.rxcui,
      drug_quantity: props.match.params.quantity,
      insurance: formData.insurance ? insuranceUploadUrl : null,
      coupon: formData.coupon ? couponUploadUrl : null,
    };

    console.log(postData);

    setRequestSubmitting(true);
    setErrors(null);

    api.post('/webhooks/rx_search_request_website', postData)
        .then(async (res) => {
          if (res.data) {
            console.log(res.data);
            if (res.data.success) {
              history.push(`/rx-request-success/${res.data.rx_order_ref}/${res.data.user_id}/${res.data.password_token}`);
            } else {
              setErrors(res.data.errors);
            }

            setRequestSubmitting(false);
          }
        })
        .catch(err => console.log(err));

  };

  const summaryYourInfoItems = [
    {
      key: '1',
      label: 'First Name',
      children: formData.first_name,
    },
    {
      key: '2',
      label: 'Last Name',
      children: formData.last_name,
    },
    {
      key: '3',
      label: 'Birth Date',
      children: dayjs(formData.birth_date).format('YYYY-MM-DD'),
    },
    {
      key: '4',
      label: 'Email',
      children: formData.email,
    },
    {
      key: '5',
      label: 'Phone',
      children: formData.phone_number,
    },
    {
      key: '6',
      label: 'Address',
      children: [formData.street, formData.city, formData.state, formData.zip_code].join(' '),
    },
  ];

  const summaryDoctorItems = [
    {
      key: '1',
      label: 'Name of Prescriber',
      children: formData.doctor_name,
    },
    {
      key: '2',
      label: 'Prescriber\'s Phone',
      children: formData.doctor_phone,
    },
  ];

  const summaryPharmacyItems = [
    {
      key: '1',
      label: 'Name of Pharmacy',
      children: formData.pharmacy_name,
    },
    {
      key: '2',
      label: 'Pharmacy\'s Phone',
      children: formData.pharmacy_phone,
    },
    {
      key: '3',
      label: 'Address',
      children: [formData.pharmacy_street, formData.pharmacy_city, formData.pharmacy_state, formData.pharmacy_zip_code].join(' '),
    },
  ];

  const summaryUploadsItems = [
    {
      key: '1',
      label: 'Insurance',
      children: formData.insurance ? formData.insurance.file.name : "N/A",
    },
    {
      key: '2',
      label: 'Coupon',
      children: formData.coupon ? formData.coupon.file.name : "N/A",
    },
  ];

  return (
    <>
      <ConfigProvider
          theme={{
            token: {
              fontFamily: "\"Inter\", sans-serif",
              fontSize: 14,
              colorBorder: "#cccccc",
              borderRadiusLG: 5,
              colorTextPlaceholder: "#757575",
              colorTextDescription: "#757575",
              colorTextTertiary:  "#757575",
              marginLG: 12,
              colorText: '#272b41',
            },
            components: {
              Form: {
                verticalLabelPadding: '0',
              },
              Select: {
                singleItemHeightLG: 52
              },
              Descriptions: {
                itemPaddingBottom: 8
              }
            },
          }}>
          {
            isAuthenticated ?
                <Header/> :
                <Home2Header/>
          }
        <>
          {/* Page Content */}
          <div className="request-content-info">
            <div className="container">
              {/* Doctor Signup */}
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="request-content">
                    <div className="request-info">
                      <div className="widget-content multistep-form">
                        <div className="medication-details">
                          <div className="back-to-search">
                            <Link to={`/search-medication/Wegovy/${props.match.params.zipCode}`}>
                              <i className="fa-solid fa-arrow-left-long" /> Back
                            </Link>
                          </div>
                          <div className="medication-details-item">
                            <span>Drug Name</span>
                            <h4>{medication.displayName}</h4>
                          </div>
                          <div className="medication-details-item">
                            <span>Dosage</span>
                            <h4>{medication.strength}</h4>
                          </div>
                          <div className="medication-details-item">
                            <span>Form</span>
                            <h4>{medication.rxnormDoseForm}</h4>
                          </div>
                          <div className="medication-details-item">
                            <span>Quantity</span>
                            <h4>{props.match.params.quantity}</h4>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-5 request-details-title-left">
                            <div className="request-details-title">
                              <h3>Submit Prescription Details</h3>
                              <p className="mb-0">
                                Please fill out the following form in order to help us find your medication.
                              </p>
                            </div>
                            <Steps
                                style={{marginBottom: 20}}
                                direction="vertical"
                                current={step}
                                onChange={onStepChange}
                                items={[
                                  {
                                    title: <strong>About You</strong>,
                                    icon: <UserOutlined />,
                                    description: 'Information about you',
                                  },
                                  {
                                    title: <strong>Your Doctor</strong>,
                                    icon: <SolutionOutlined />,
                                    description: 'Information about doctor who prescribed this medication',
                                  },
                                  {
                                    title: <strong>Your Pharmacy</strong>,
                                    icon: <HomeOutlined />,
                                    description: 'Which pharmacy currently has your prescription?',
                                  },
                                  {
                                    title: <strong>Uploads</strong>,
                                    icon: <CloudUploadOutlined />,
                                    description: 'Upload coupons and insurance (if any)',
                                  },
                                  {
                                    title: <strong>Review</strong>,
                                    icon: <FileDoneOutlined />,
                                    description: 'Review and submit',
                                  },
                                ]}
                            />
                            </div>
                          <div className="col-lg-7">
                            {step === 0 &&
                            <Form
                                layout="vertical"
                                form={form0}
                                name="step0"
                                onFinish={onFinishStep0}
                                initialValues={authUser && authUser.patient ? {
                                  first_name: authUser.patient.first_name,
                                  last_name: authUser.patient.last_name,
                                  birth_date: dayjs(authUser.patient.birthdate),
                                  email: authUser.email,
                                  phone_number: authUser.patient.phone,
                                  street: authUser.patient.address.street,
                                  city: authUser.patient.address.city,
                                  state: authUser.patient.address.state,
                                  zip_code: props.match.params.zipCode,
                                } : {zip_code: props.match.params.zipCode}}
                            >
                              <div className={'row'}>
                                <Form.Item
                                    className={'col-lg-6'}
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    label="First Name"
                                    name="first_name"
                                    rules={[{required: true, message: "First name is required"}]}>
                                  <Input
                                      size={'large'}
                                      placeholder="First Name"
                                  />
                                </Form.Item>
                                <Form.Item
                                    className={'col-lg-6'}
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    label="Last Name"
                                    name="last_name"
                                    rules={[{required: true, message: "Last name is required"}]}>
                                  <Input
                                      size={'large'}
                                      placeholder="Last Name"
                                  />
                                </Form.Item>
                              </div>
                              <div className={'row'}>
                                <Form.Item
                                    className={'col-lg-6'}
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    name="birth_date"
                                    label="Date of Birth"
                                    rules={[{required: true, message: "Date of birth is required"}]}>
                                  <DatePicker
                                      style={{width: '100%'}}
                                      maxDate={dayjs().subtract(18, 'year').month(11).date(31)}
                                      size={'large'}/>
                                </Form.Item>
                                <Form.Item
                                    className={'col-lg-6'}
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    name="email"
                                    label="Email"
                                    rules={[{type: 'email'}, {required: true, message: "Email is required"}]}>
                                  <Input
                                      size={'large'}
                                      disabled={authUser && authUser.patient}
                                      placeholder="Email"/>
                                </Form.Item>
                              </div>
                              <Form.Item
                                  layout="vertical"
                                  labelCol={{span: 24}}
                                  wrapperCol={{span: 24}}
                                  label="Phone Number"
                                  name="phone_number"
                                  rules={[{required: true, message: "Phone number is required"}]}>
                                <PhoneInput
                                    country={'us'}
                                    onlyCountries={['us']}
                                    name="phoneNumber"
                                    type="text"
                                />
                              </Form.Item>
                              <div className={'row'}>
                                <Form.Item
                                    className={'col-lg-6'}
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    label="Address"
                                    name="street"
                                    rules={[{required: true, message: "Address is required"}]}>
                                  <Input
                                      size={'large'}
                                      placeholder="Address"
                                  />
                                </Form.Item>
                                <Form.Item
                                    className={'col-lg-6'}
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    label="City"
                                    name="city"
                                    rules={[{required: true, message: "City is required"}]}>
                                  <Input
                                      size={'large'}
                                      placeholder="City"
                                  />
                                </Form.Item>
                              </div>
                              <div className={'row'}>
                                <Form.Item
                                    className={'col-lg-6'}
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    label="state"
                                    name="state"
                                    rules={[{required: true, message: "State is required"}]}>
                                  <Input
                                      size={'large'}
                                      placeholder="State"
                                  />
                                </Form.Item>
                                <Form.Item
                                    className={'col-lg-6'}
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    label="Zip Code"
                                    name="zip_code"
                                    rules={[{required: true, message: "Zip code is required"}]}>
                                  <Input
                                      size={'large'}
                                      placeholder="Zip Code"
                                  />
                                </Form.Item>
                              </div>
                              <Form.Item wrapperCol={{span: 24}}>
                                <Button style={{width: '100%'}} type="primary" htmlType="submit" size="large"
                                        icon={<RightOutlined />} iconPosition='end'>
                                  Next
                                </Button>
                              </Form.Item>
                            </Form>
                          }
                          {step === 1 &&
                            <Form
                                layout="vertical"
                                form={form1}
                                name="step1"
                                onFinish={onFinishStep1}
                                initialValues={rxRequests.length > 0 ? {
                                  doctor_name: rxRequests[rxRequests.length - 1].prescriber_name,
                                  doctor_phone: rxRequests[rxRequests.length - 1].prescriber_phone,
                                } : {}}
                            >
                              <Form.Item
                                  layout="vertical"
                                  labelCol={{span: 24}}
                                  wrapperCol={{span: 24}}
                                  label="Name of Prescriber"
                                  name="doctor_name"
                                  rules={[{required: true, message: "Name of prescriber is required"}]}>
                                <Input
                                    size={'large'}
                                    placeholder="Name of Prescriber"
                                />
                              </Form.Item>
                              <Form.Item
                                  layout="vertical"
                                  labelCol={{span: 24}}
                                  wrapperCol={{span: 24}}
                                  label="Prescriber's Phone"
                                  name="doctor_phone"
                                  rules={[{required: true, message: "Prescriber's phone number is required"}]}>
                                <PhoneInput
                                    country={'us'}
                                    onlyCountries={['us']}
                                    name="doctorPhone"
                                    type="text"
                                />
                              </Form.Item>
                              <div className={'row'}>
                                <Form.Item className={'col-lg-6'}>
                                  <Button style={{width: '100%'}} type="default" size="large" onClick={() => setStep(0)}
                                          icon={<LeftOutlined />}>
                                    Previous
                                  </Button>
                                </Form.Item>
                                <Form.Item className={'col-lg-6'}>
                                  <Button style={{width: '100%'}} type="primary" htmlType="submit" size="large"
                                          icon={<RightOutlined />} iconPosition='end'>
                                    Next
                                  </Button>
                                </Form.Item>
                              </div>
                            </Form>
                          }
                          {step === 2 &&
                            <Form
                                layout="vertical"
                                form={form2}
                                name="step2"
                                onFinish={onFinishStep2}
                                initialValues={rxRequests.length > 0 ? {
                                  pharmacy_name: rxRequests[rxRequests.length - 1].rx_pharmacy_name,
                                  pharmacy_phone: rxRequests[rxRequests.length - 1].rx_pharmacy_phone,
                                  pharmacy_street: rxRequests[rxRequests.length - 1].address.street,
                                  pharmacy_city: rxRequests[rxRequests.length - 1].address.city,
                                  pharmacy_state: rxRequests[rxRequests.length - 1].address.state,
                                  pharmacy_zip_code: rxRequests[rxRequests.length - 1].address.zip_code,
                                } : {}}
                            >
                              <Form.Item
                                  layout="vertical"
                                  labelCol={{span: 24}}
                                  wrapperCol={{span: 24}}
                                  label="Name of Pharmacy"
                                  name="pharmacy_name"
                                  rules={[{required: true, message: "Name of pharmacy is required"}]}>
                                <Input
                                    size={'large'}
                                    placeholder="Name of Pharmacy"
                                />
                              </Form.Item>
                              <Form.Item
                                  layout="vertical"
                                  labelCol={{span: 24}}
                                  wrapperCol={{span: 24}}
                                  label="Pharmacy's Phone"
                                  name="pharmacy_phone"
                                  rules={[{required: true, message: "Pharmacy's phone number is required"}]}>
                                <PhoneInput
                                    country={'us'}
                                    onlyCountries={['us']}
                                    name="pharmacyPhone"
                                    type="text"
                                />
                              </Form.Item>
                              <div className={'row'}>
                                <Form.Item
                                    className={'col-lg-6'}
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    label="Address of Pharmacy"
                                    name="pharmacy_street"
                                    rules={[{required: true, message: "Address is required"}]}>
                                  <Input
                                      size={'large'}
                                      placeholder="Address of Pharmacy"
                                  />
                                </Form.Item>
                                <Form.Item
                                    className={'col-lg-6'}
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    label="City"
                                    name="pharmacy_city"
                                    rules={[{required: true, message: "City is required"}]}>
                                  <Input
                                      size={'large'}
                                      placeholder="City"
                                  />
                                </Form.Item>
                              </div>
                              <div className={'row'}>
                                <Form.Item
                                    className={'col-lg-6'}
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    label="State"
                                    name="pharmacy_state"
                                    rules={[{required: true, message: "State is required"}]}>
                                  <Input
                                      size={'large'}
                                      placeholder="State"
                                  />
                                </Form.Item>
                                <Form.Item
                                    className={'col-lg-6'}
                                    layout="vertical"
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    label="Zip Code"
                                    name="pharmacy_zip_code"
                                    rules={[{required: true, message: "Zip code is required"}]}>
                                  <Input
                                      size={'large'}
                                      placeholder="Zip Code"
                                  />
                                </Form.Item>
                              </div>
                              <div className={'row'}>
                                <Form.Item className={'col-lg-6'}>
                                  <Button style={{width: '100%'}} type="default" size="large" onClick={() => setStep(1)}
                                          icon={<LeftOutlined />}>
                                    Previous
                                  </Button>
                                </Form.Item>
                                <Form.Item className={'col-lg-6'}>
                                  <Button style={{width: '100%'}} type="primary" htmlType="submit" size="large"
                                          icon={<RightOutlined />} iconPosition='end'>
                                    Next
                                  </Button>
                                </Form.Item>
                              </div>
                            </Form>
                          }
                          {step === 3 &&
                            <Form
                                layout="vertical"
                                form={form3}
                                name="step3"
                                onFinish={onFinishStep3}
                            >
                              <Form.Item
                                  layout="vertical"
                                  labelCol={{span: 24}}
                                  wrapperCol={{span: 24}}
                                  label="Do you have insurance?"
                                  name="insurance"
                                  valuePropName="file"
                                  rules={[]}>
                                <Upload.Dragger
                                    name="insurance"
                                    method="put"
                                    action={async (file) => {
                                      const resp = await api.get('/rx_search_requests/upload_presigned_url.json?filename=' + file.name)

                                      console.log(resp);
                                      if (resp.data.success) {
                                        setInsuranceUploadUrl(resp.data.public_url);
                                        return resp.data.presigned_url;
                                      }

                                      return false;
                                    }}
                                    customRequest={(options) => {
                                      const reader = new FileReader();

                                      reader.onload = (evt) => {
                                        axios.put(options.action, evt.target.result, {
                                          headers: {
                                            'Content-Type': 'application/octet-stream'
                                          }
                                        }).then((res) => {
                                          options.onSuccess(res.data, options.file)
                                        }).catch((err) => {
                                          console.log(err)
                                        })
                                      };
                                      reader.readAsArrayBuffer(options.file);

                                    }}
                                    maxCount={1}>
                                  <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                  </p>
                                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                  <p className="ant-upload-hint">Upload supported file (Max 15MB).</p>
                                </Upload.Dragger>
                              </Form.Item>
                              <Form.Item
                                  layout="vertical"
                                  labelCol={{span: 24}}
                                  wrapperCol={{span: 24}}
                                  label="Have a Coupon/Discount Card?"
                                  name="coupon"
                                  valuePropName="file"
                                  rules={[]}>
                                <Upload.Dragger
                                    method="put"
                                    name="coupon"
                                    action={async (file) => {
                                      const resp = await api.get('/rx_search_requests/upload_presigned_url.json?filename=' + file.name)

                                      console.log(resp);
                                      if (resp.data.success) {
                                        setCouponUploadUrl(resp.data.public_url);
                                        return resp.data.presigned_url;
                                      }

                                      return false;
                                    }}
                                    customRequest={(options) => {
                                      const reader = new FileReader();

                                      reader.onload = (evt) => {
                                        axios.put(options.action, evt.target.result, {
                                          headers: {
                                            'Content-Type': 'application/octet-stream'
                                          }
                                        }).then((res) => {
                                          options.onSuccess(res.data, options.file)
                                        }).catch((err) => {
                                          console.log(err)
                                        })
                                      };
                                      reader.readAsArrayBuffer(options.file);

                                    }}
                                    maxCount={1}>
                                  <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                  </p>
                                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                  <p className="ant-upload-hint">Upload supported file (Max 15MB).</p>
                                </Upload.Dragger>
                              </Form.Item>
                              <div className={'row'}>
                                <Form.Item className={'col-lg-6'}>
                                  <Button style={{width: '100%'}} type="default" size="large" onClick={() => setStep(2)}
                                    icon={<LeftOutlined />}>
                                    Previous
                                  </Button>
                                </Form.Item>
                                <Form.Item className={'col-lg-6'}>
                                  <Button style={{width: '100%'}} type="primary" htmlType="submit" size="large"
                                    icon={<RightOutlined />} iconPosition='end'>
                                    Next
                                  </Button>
                                </Form.Item>
                              </div>
                            </Form>
                          }
                          {step === 4 &&
                            <Form
                                layout="vertical"
                                form={form4}
                                name="step4"
                                onFinish={onFinishStep4}>
                              <div className="rx-request-summary">
                                <Descriptions colon={false} title="Your Info" layout="vertical" items={summaryYourInfoItems} />
                                <Divider/>
                                <Descriptions colon={false} title="Doctor" layout="vertical" items={summaryDoctorItems} />
                                <Divider/>
                                <Descriptions colon={false} title="Pharmacy" layout="vertical" items={summaryPharmacyItems} />
                                <Divider/>
                                <Descriptions colon={false} title="Uploads" layout="vertical" items={summaryUploadsItems} />
                              </div>
                              <Form.Item
                                name="terms_conditions"
                                valuePropName="checked"
                                rules={[
                                  {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error('Agreement to the terms & conditions is required')),
                                  },
                                ]}>
                                <Checkbox>I agree to the <Button style={{padding: 0}} type={'link'} onClick={showTermsModal}>terms & conditions</Button></Checkbox>
                              </Form.Item>
                              <Form.Item
                                name="messages_consent"
                                valuePropName="checked"
                                rules={[
                                  {
                                    validator: (_, value) =>
                                        value ? Promise.resolve() : Promise.reject(new Error('Consent to receive text messages is required')),
                                  },
                                ]}>
                                <Checkbox>Consent to receive <Button style={{padding: 0}} type={'link'} onClick={showConsentModal}>text messages</Button></Checkbox>
                              </Form.Item>
                              <Modal cancelButtonProps={{ style: { display: 'none' } }} title="Terms & Conditions" open={isTermsModalOpen} onOk={handleTermsModalOk} onCancel={handleTermsModalOk} onClose={handleTermsModalOk}>
                                <h2>HIPAA Privacy Notice</h2>
                                <h3>Last Reviewed: 06/29/2023</h3>
                                <br/>
                                <p>This HIPAA Privacy Notice (the "Notice") describes how your protected health information (PHI) may be used and disclosed by Prescription Quest (referred to as "we," "us," or "our"), and your rights regarding your PHI. We are committed to maintaining the privacy and security of your PHI in accordance with the Health Insurance Portability and Accountability Act (HIPAA) and other applicable laws.</p>
                                <br/>

                                <h3>1. Uses and Disclosures of PHI</h3>

                                <p>We may use and disclose your PHI for the following purposes:</p>

                                <p>Treatment: We may use and disclose your PHI to provide, coordinate, or manage your healthcare and related services. This includes sharing information with healthcare professionals involved in your care.</p>

                                <p>Payment: We may use and disclose your PHI to bill and collect payment for the healthcare services we provide. This may involve sharing information with insurance companies, government agencies, or third-party payers.</p>

                                <p>Healthcare Operations: We may use and disclose your PHI for our internal operations, such as quality assessment, staff training, and conducting audits or compliance activities.</p>

                                <p>Business Associates: We may disclose your PHI to third-party service providers, known as business associates, who perform functions on our behalf and require access to PHI to provide their services. We have contracts in place with these business associates to protect the privacy and security of your PHI.</p>

                                <p>Required by Law: We may use and disclose your PHI when required by law, such as for public health activities, reporting abuse or neglect, or to comply with a court order or legal process.</p>

                                <h3>2. Your Rights</h3>

                                <p>You have the following rights regarding your PHI:</p>

                                <p>Right to Access: You have the right to request access to your PHI in our records. We may charge a reasonable fee for providing copies of your PHI.</p>

                                <p>Right to Request Restrictions: You have the right to request restrictions on certain uses and disclosures of your PHI. However, we are not required to agree to your request if it would affect your treatment or our ability to collect payment for services.</p>

                                <p>Right to Confidential Communications: You have the right to request that we communicate with you about your PHI in a certain way or at a specific location to maintain confidentiality.</p>

                                <p>Right to Amend: If you believe that your PHI in our records is incorrect or incomplete, you may request an amendment. We may deny your request under certain circumstances.</p>

                                <p>Right to an Accounting of Disclosures: You have the right to request an accounting of certain disclosures of your PHI that we have made.</p>

                                <p>Right to a Copy of this Notice: You have the right to obtain a paper copy of this Notice upon request, even if you have received an electronic copy.</p>

                                <h3>3. Privacy and Security Practices</h3>

                                <p>We maintain physical, technical, and administrative safeguards to protect your PHI from unauthorized access, use, or disclosure. We regularly review and update our security measures to ensure the privacy and integrity of your PHI.</p>

                                <h3>4. Changes to this Notice</h3>

                                <p>We reserve the right to amend this Notice at any time. Any material changes will be promptly posted on our website and provided to you in writing, if requested.</p>

                                <h3>5. Contact Information</h3>

                                <p>If you have any questions, concerns, or complaints about this Notice or our privacy practices, please contact:</p>

                                <p>Prescription Quest<br/>

                                (347) 647-9851<br/>

                                <a href="mailto:privacy@prescriptionsquest.com">privacy@prescriptionsquest.com</a></p>

                                <p>If you believe your privacy rights have been violated, you may file a complaint with us or with the Office for Civil Rights of the U.S. Department of Health and Human Services.</p>

                                <p>Prescription Quest is committed to protecting your PHI and respecting your privacy rights. We will not retaliate against you for filing a complaint.</p>

                                <p>Please also review our <a href="https://www.prescriptionsquest.com/copy-of-hipaa-privacy-notice" target="_self">Do not sell or share my personal Information Policy</a>.</p>

                              </Modal>
                              <Modal  cancelButtonProps={{ style: { display: 'none' } }} title="Message Consent" open={isConsentModalOpen} onOk={handleConsentModalOk} onCancel={handleConsentModalOk} onClose={handleConsentModalOk}>
                                <h3>Pharmacy Consent to Receive Text Messages</h3>
                                <br/>
                                <p>By providing your phone number, you consent to receive text messages from Prescription Quest. These messages may include important updates, promotional offers, and other information related to our services. Message and data rates may apply. You can opt out at any time by replying STOP to any of our messages. For more information, please review our Privacy Policy and Terms of Service.</p>
                              </Modal>
                              <div className={'row'}>
                                <Form.Item className={'col-lg-6'}>
                                  <Button style={{width: '100%'}} type="default" size="large" onClick={() => setStep(0)}
                                          icon={<EditOutlined />} disabled={requestSubmitting}>
                                    Edit
                                  </Button>
                                </Form.Item>
                                <Form.Item className={'col-lg-6'}>
                                  <Button style={{width: '100%'}} type="primary" htmlType="submit" size="large"
                                          disabled={requestSubmitting}
                                          icon={requestSubmitting ? <LoadingOutlined /> : <CheckOutlined />}>
                                     Submit Request
                                  </Button>
                                </Form.Item>
                              </div>
                            </Form>
                          }
                          {errors
                              ? Object.keys(errors).map((errorKey) =>
                                  Object.keys(errors[errorKey]).map((errorObject) => (
                                      Array.isArray(errors[errorKey][errorObject]) ? errors[errorKey][errorObject].map((error, index) =>
                                          <Alert
                                              key={index}
                                              message="Error"
                                              description={`${errorKey} ${errorObject} ${error}.`}
                                              type="error"
                                              showIcon
                                          />) : ''
                                      )
                                  ))
                              : null
                          }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /Doctor Signup */}
            </div>
          </div>
          {/* /Page Content */}
        </>
        <Home2Footer />
      </ConfigProvider>
    </>
  );
};

export default RxRequest;
