import React, { useEffect, useState } from "react";
import axios from 'axios';
import Header from "../../header";
import Home2Footer from "./footer";
import { AutoComplete, Select, ConfigProvider, Form, Button, InputNumber } from "antd";
import { LoadingOutlined } from "@ant-design/icons"
import AOS from "aos";
import "aos/dist/aos.css";
import {useHistory} from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import Home2Header from "./header";

const SearchMedication = (props) => {
  const authUser = useAuthUser();
  const isAuthenticated = useIsAuthenticated();

  const [medications, setMedications] = useState([]);
  const [medFormOption, setMedFormOption] = useState(null);
  const [medicationsLoading, setMedicationsLoading] = useState(false);
  // const [medStrengthOptions, setMedStrengthOptions] = useState(null);
  // const [medStrengthOptionsLoading, setMedStrengthOptionsLoading] = useState(false);

  const [searchLoading, setSearchLoading] = useState(false);
  const [searchMedicationName, setSearchMedicationName] = useState('');
  const [searchMedicationFullName, setSearchMedicationFullName] = useState('');
  const [foundPharmacies, setFoundPharmacies] = useState([]);
  const [foundOtherPharmacies, setFoundOtherPharmacies] = useState([]);
  const [searchZipcode, setSearchZipcode] = useState('');
  const [searchStateName, setSearchStateName] = useState('');

  AOS.init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });

    updateOptions(props.match.params.drugName);
  }, []);
  const handleScroll = () => {
    AOS.refresh();
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const updateOptions = (data) => {
    setMedicationsLoading(true);
    // setMedStrengthOptionsLoading(true);

    axios.get('https://rxnav.nlm.nih.gov/REST/drugs.json?name=' + data + '&expand=psn')
        .then(async (res) => {
          if (res.data.drugGroup.conceptGroup) {
            const rxcuis = res.data.drugGroup.conceptGroup.map((sgg) =>
                sgg.conceptProperties ? sgg.conceptProperties.map(cp => [cp.rxcui, cp.psn]) : []
            ).reduce(function(prev, next) {
              return prev.concat(next);
            });

            const searchResultMedications = [];
            for (let rxcui of rxcuis) {
              const resp = await axios.get('https://rxnav.nlm.nih.gov/REST/RxTerms/rxcui/' + rxcui[0] + '/allinfo.json')

              if (resp.data.rxtermsProperties) {
                searchResultMedications.push({...resp.data.rxtermsProperties, psn: rxcui[1]});
                console.log(resp.data);
              }
            }
            setMedications(searchResultMedications);

            setMedicationsLoading(false);
          }
        })
        .catch(err => console.log(err));
  };
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = (values) => {
    console.log(values);

    setSearchLoading(true);

    axios.get(`/pharmacies/search.json?zipcode=${props.match.params.zipCode}&drug_rxcui=${values.drugStrength}&drug_quantity=${values.drugQuantity}`)
        .then(async (res) => {
          if (res.data) {
            console.log(res.data);

            setFoundPharmacies(res.data.in_state)
            setFoundOtherPharmacies(res.data.nearby_states)
            setSearchStateName(res.data.state_name)
          }

          const medicationName = medications.find((item) => item.rxcui === values.drugStrength);
          setSearchMedicationName(medicationName.psn);
          setSearchMedicationFullName(medicationName.psn);
          setSearchZipcode(props.match.params.zipCode)

          setSearchLoading(false);
        })
        .catch(err => console.log(err));
  };

  const onRequestTransfer = (pharmacies) => {
    history.push(`/rx-request/${pharmacies.map((pharmacy) => pharmacy.id)}/${form.getFieldValue('drugStrength')}/${form.getFieldValue('drugQuantity')}/${searchZipcode}/${searchMedicationName}`);
  }

  const medFormOptions = medications.filter(function(value, index, array) {
    return array.findIndex((i) => i.rxnormDoseForm === value.rxnormDoseForm) === index;})
      .map((j) => ({label: j.rxnormDoseForm, value: j.rxnormDoseForm}))
      .sort((a, b) => a.label.localeCompare(b.label));

  const medStrengthOptions = medications.filter((value, index, array) => value.rxnormDoseForm === medFormOption)
      .map((j) => ({label: j.psn, value: j.rxcui}))
      .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div className="main-wrapper">
      {
        isAuthenticated ?
            <Header/> :
            <Home2Header/>
      }
      {/* Home Banner */}
      <section className="section section-search-medication">
        <div className="container-fluid">
          <div className="banner-wrapper">
            <div className="banner-header text-center aos" data-aos="fade-up">
              <h2>Enter your medication details</h2>
            </div>
            {/* Search */}
            <div className="search-box">
              <ConfigProvider
                  theme={{
                    token: {
                      fontSize: 14,
                      colorBorder: "#cccccc",
                      borderRadiusLG: 5,
                      colorTextPlaceholder: "#757575",
                      colorTextDescription: "#757575",
                    },
                    components: {
                      Select: {
                        singleItemHeightLG: 52
                      },
                    },
                  }}>

                <Form
                    form={form}
                    name="control-hooks"
                    onFinish={onFinish}
                    // style={{ maxWidth: 600 }}
                    initialValues={{drugName: props.match.params.drugName}}
                >
                  <div className="form-group">
                    <div className="mb-3 search-medication-name aos" data-aos="fade-up">
                      <Form.Item
                          name="drugName"
                          rules={[{ required: true, message: "Name or brand is required" }]}
                          help="Ex : Wegovy">
                        <AutoComplete
                            disabled
                            style={{width: '100%', height: 'auto'}}
                            children={<input
                                type="text"
                                style={{fontSize: 16, height: 52, backgroundColor: 'rgba(0, 0, 0, 0.04)'}}
                                className="form-control"
                                placeholder="Generic Name, Brand Etc."/>}
                        />
                      </Form.Item>
                    </div>
                    <div className="mb-3 search-medication-form aos" data-aos="fade-up">
                      <Form.Item name="drugForm" rules={[{ required: true, message: "Medication form is required" }]}
                        help="Ex : Auto-Injector">
                        <Select
                          style={{width: '100%', minHeight: 46}}
                          loading={medicationsLoading}
                          disabled={medicationsLoading}
                          options={medFormOptions}
                          value={medFormOption}
                          onSelect={(value) => setMedFormOption(value)}
                          size={'large'}
                          placeholder="Select Drug Form"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="mb-3 search-medication-strength aos" data-aos="fade-up">
                      <Form.Item name="drugStrength" rules={[{ required: true, message: "Medication strength is required" }]}
                                 help="Ex : 2 mg/ml">
                        <Select
                            style={{width: '100%', minHeight: 46}}
                            loading={medicationsLoading}
                            disabled={medicationsLoading || !medFormOption}
                            options={medStrengthOptions}
                            size={'large'}
                            placeholder="Select Drug Strength"
                        />
                      </Form.Item>
                    </div>
                    <div className="mb-3 search-medication-strength aos" data-aos="fade-up">
                      <Form.Item name="drugQuantity" rules={[{ required: true, message: "Medication strength is required" }]}
                                 help="Ex : 100">
                        <InputNumber
                            style={{width: '100%', minHeight: 52, lineHeight: '50px'}}
                            disabled={medicationsLoading || !medFormOption}
                            options={[]}
                            size={'large'}
                            placeholder="Select Quantity"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/*<Button*/}
                  {/*  htmlType="submit"*/}
                  {/*  className="btn btn-primary search-btn mt-0 aos"*/}
                  {/*  data-aos="fade-up"*/}
                  {/*  disabled={searchLoading}*/}
                  {/*  icon=*/}
                  {/*>*/}
                  {/*   Search*/}
                  {/*</Button>*/}
                  <button
                      type="submit"
                      className="btn btn-primary search-btn mt-0 aos"
                      data-aos="fade-up"
                  >
                    {searchLoading ?
                        <LoadingOutlined /> :
                        <i className="fas fa-search" />}
                    <span>Search</span>
                  </button>
                </Form>

                {!searchLoading && searchMedicationName !== "" &&
                  <div className="search-result">
                    <h4>
                      Results for <span>{searchMedicationName}</span> near <span>{searchZipcode}</span>
                    </h4>

                    <div className="search-result-box">
                      <h3 className="mb-0">
                        Pharmacies in <span>{searchStateName}</span>: <span className={`pharmacies-count ${foundPharmacies.length === 0 ? 'no-result' : ''}`}>{foundPharmacies.length}</span>
                      </h3>
                      {foundPharmacies.length > 0 &&
                        <Button
                            type="submit"
                            className="btn btn-primary transfer-btn"
                            onClick={() => onRequestTransfer(foundPharmacies)}
                        >
                          Get Your Medication
                        </Button>
                      }
                    </div>
                    <div className="search-result-box">
                      <h3 className="mb-0">
                        Pharmacies in Other nearby States: <span className={`pharmacies-count ${foundOtherPharmacies.length === 0 ? 'no-result' : ''}`}>{foundOtherPharmacies.length}</span>
                      </h3>
                      {foundOtherPharmacies.length > 0 &&
                        <Button
                            type="submit"
                            className="btn btn-primary transfer-btn"
                            onClick={() => onRequestTransfer(foundOtherPharmacies)}
                        >
                          Get Your Medication
                        </Button>
                      }
                    </div>
                  </div>
                }

              </ConfigProvider>
            </div>
            {/* /Search */}
          </div>
        </div>
      </section>
      <Home2Footer />
    </div>
  );
};

export default SearchMedication;
