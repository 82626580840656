import React, { useEffect } from "react";
import Home2Header from "../../header";
import Home2Footer from "../../footer";

const PharmacyRegisterSuccess = (props) => {
  return (
      <>
        <Home2Header/>
        <div className="content success-page-cont">
          <div className="container-fluid">
            <div className="row justify-content-center">
              {/* Success Card */}
              <div className="card success-card">
                <div className="card-body">
                  <div className="success-cont">
                    <i className="fas fa-check" />
                    <h3>Success!</h3>
                    <h4>Thank you for completing your registration. Your application is under review. Please <a className="link-info" target="_blank" href="https://calendly.com/prescriptionsquest">click here</a> to schedule your onboarding call with the Admin.</h4>
                    <a target="_blank" className="btn btn-outline-primary" href="https://calendly.com/prescriptionsquest">Schedule an onboarding call</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<Home2Footer />*/}
      </>
  );
};

export default PharmacyRegisterSuccess;
