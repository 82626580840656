import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Home2Header from "./header";
import Home2Footer from "./footer";
import Header from "../../header";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import api from "../../../api";

const RxRequestSuccess = (props) => {
  const authUser = useAuthUser();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!authUser) {
      // const img = document.createElement('img')
      // const url = `https://www.shareasale.com/sale.cfm?tracking=${props.match.params.userId}&amount=0.00&merchantID=160301&transtype=lead`
      //
      // img.setAttribute('width', '1')
      // img.setAttribute('height', '1')
      // img.setAttribute('src', url)
      //
      // document.body.appendChild(img)

      // console.log('shareasale_track submitted!');
      // api.post('/webhooks/shareasale_track', {})
      //     .then(async (res) => {
      //       if (res.data) {
      //         console.log('shareasale_track success!');
      //       }
      //     })
      //     .catch(err => console.log(err));

    }
  }, []);

  return (
    <>
      {
        isAuthenticated ?
            <Header/> :
            <Home2Header/>
      }
      <div className="content success-page-cont">
        <div className="container-fluid">
          <div className="row justify-content-center">
            {/* Success Card */}
            <div className="card success-card">
              <div className="card-body">
                <div className="success-cont">
                  <i className="fas fa-check" />
                  <h3>Success!</h3>
                  <h4>You'll receive the pharmacy details via text and email within the next 10 minutes.</h4>
                  <p className="mb-0">Order ID: REF#{props.match.params.orderRef}</p>
                  <br/>
                  {
                    !authUser &&
                      <>
                        <h4>To track your request and communicate with pharmacies, please follow <a className="text-primary text-decoration-underline text-primary-emphasis" href={`/accounts/create-password/${props.match.params.userId}/${props.match.params.passwordToken}`}>this link</a> and create your password.</h4>
                        <img src={`https://www.shareasale.com/sale.cfm?tracking=${props.match.params.userId}&amount=0.00&merchantID=160301&transtype=lead`} width="1" height="1"/>
                        <script src="https://www.dwin1.com/105461.js" type="text/javascript" defer="defer"></script>
                      </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Home2Footer />
    </>
  );
};

export default RxRequestSuccess;
