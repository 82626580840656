import React, {useEffect, useState} from 'react';
import {Route, Redirect, withRouter, Link} from 'react-router-dom';
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import Header from "../../components/header";
import DoctorFooter from "../../components/common/doctorFooter";
import DoctorSidebar from "../../components/doctors/sidebar";
import DashboardSidebar from "../../components/patients/dashboard/sidebar/sidebar";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import api from "../../api";

const PrivateRoute = ({component: Component, ...rest}) => {
  const isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser();
  const [orders, setOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    if (authUser.pharmacy) {
      fetchOrders();
      fetchTransactions();
    }
  }, []);

  const fetchOrders = () => {
    api.get(`/pharmacies/${authUser.pharmacy.id}/rx_search_orders.json`)
        .then((res) => {
          if (res.data) {
            const data = res.data.filter((order) => order.rx_drug)
            setOrders(data)
            console.log(data)
          }
        })
        .catch(err => console.log(err));
  }
  const fetchTransactions = () => {
    api.get(`/pharmacies/${authUser.pharmacy.id}/rx_search_transactions.json`)
        .then((res) => {
          console.log(res.data)
          if (res.data) {
            setTransactions(res.data)
          }
        })
        .catch(err => console.log(err));
  }

  return (
      <Route {...rest} render={props => (
          isAuthenticated ? (
              <div>
                <Header {...props} />
                {/* Breadcrumb */}
                <div className="breadcrumb-bar-two">
                  <div className="container">
                    {/*<div className="row align-items-center inner-banner">*/}
                    {/*  <div className="col-md-12 col-12 text-center">*/}
                    {/*    <h2 className="breadcrumb-title">Dashboard</h2>*/}
                    {/*    <nav aria-label="breadcrumb" className="page-breadcrumb">*/}
                    {/*      <ol className="breadcrumb">*/}
                    {/*        <li className="breadcrumb-item">*/}
                    {/*          <Link to="/">Home</Link>*/}
                    {/*        </li>*/}
                    {/*        <li className="breadcrumb-item" aria-current="page">*/}
                    {/*          Dashboard*/}
                    {/*        </li>*/}
                    {/*      </ol>*/}
                    {/*    </nav>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
                <div className="content">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-4 col-xl-3 theiaStickySidebar">
                        <div className="stickybar">
                          {/* Profile Sidebar */}
                          {authUser.pharmacy ?
                              <DoctorSidebar
                                  pendingRequests={orders.filter((order) => !order.accepted && !order.declined && !order.other_rx_search_transaction).length}
                                  pendingTransactions={transactions.filter(transaction => transaction.status === 'shipping').length}
                              /> : (
                                  authUser.patient ?
                                      <DashboardSidebar/> : null
                              )
                          }
                          {/* /Profile Sidebar */}
                        </div>
                      </div>
                      <Component {...props}/>
                    </div>
                  </div>
                </div>
                <DoctorFooter {...props} />
              </div>
          ) : (
              <Redirect to={{
                pathname: '/login',
                state: {from: props.location}
              }}/>
          )
      )}
      />
  );
};

export default (PrivateRoute);