import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import RxOrders from '../../doctors/rxorders'
import api from "../../../api";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import RxOrder from "../../doctors/rxorders/rxorder";
import DrugPrompt from "../../doctors/drugprompt";

function Notification() {
  const authUser = useAuthUser();
  const [orders, setOrders] = useState([]);
  const [drugPrompts, setDrugPrompts] = useState([]);

  useEffect(() => {
    fetchOrders();
    fetchDrugPrompts();
  }, []);

  const fetchOrders = () => {
    if (authUser.pharmacy) {
      api.get(`/pharmacies/${authUser.pharmacy?.id}/rx_search_orders.json`)
          .then((res) => {
            if (res.data) {
              const data = res.data.filter((order) => order.rx_drug && !order.accepted && !order.declined && !order.other_rx_search_transaction)
              setOrders(data)
            }
          })
          .catch(err => console.log(err));
    }
  }

  const acceptOrder = (id) => {
    api.patch('/rx_search_orders/' + id + '.json', {accepted: true, declined: false})
        .then((res) => {
          if (res.data) {
            const data = orders.map((order) => order.id === res.data.id ? res.data : order);
            setOrders(data)
          }
        })
        .catch(err => console.log(err));
  }

  const declineOrder = (id) => {
    api.patch('/rx_search_orders/' + id + '.json', {declined: true, accepted: false})
        .then((res) => {
          if (res.data) {
            const data = orders.map((order) => order.id === res.data.id ? res.data : order);
            setOrders(data)
          }
        })
        .catch(err => console.log(err));
  }

  const fetchDrugPrompts = () => {
    if (authUser.pharmacy) {
      api.get(`/pharmacies/${authUser.pharmacy?.id}/pharmacy_drug_checks.json`)
          .then((res) => {
            if (res.data) {
              const data = res.data.filter((prompt) => prompt.rx_drug && !prompt.available && !prompt.unavailable)
              setDrugPrompts(data)
            }
          })
          .catch(err => console.log(err));
    }
  }

  const acceptDrugPrompt = (id) => {
    api.patch('/pharmacy_drug_checks/' + id + '.json', {available: true, unavailable: false})
        .then((res) => {
          if (res.data) {
            const data = drugPrompts.map((prompt) => prompt.id === res.data.id ? res.data : prompt);
            setDrugPrompts(data)
          }
        })
        .catch(err => console.log(err));
  }

  const declineDrugPrompt = (id) => {
    api.patch('/pharmacy_drug_checks/' + id + '.json', {available: false, unavailable: true})
        .then((res) => {
          if (res.data) {
            const data = drugPrompts.map((prompt) => prompt.id === res.data.id ? res.data : prompt);
            setDrugPrompts(data)
          }
        })
        .catch(err => console.log(err));
  }

  const orderColumns = orders.map((order) => (
      <RxOrder key={order.id} order={order} accept={acceptOrder} decline={declineOrder}/>
  ))

  const drugColumns = drugPrompts.map((prompt) => (
      <DrugPrompt key={prompt.id} prompt={prompt} accept={acceptDrugPrompt} decline={declineDrugPrompt}/>
  ))

  return (
    <>
    {/* Notifications */}
    <li className="nav-item dropdown noti-nav me-3 pe-0">
      <Link
        to="#"
        className="dropdown-toggle nav-link p-0"
        data-bs-toggle="dropdown"
      >
        <i className="fa-solid fa-bell" /> <span className="badge badge-warning">{orders.length + drugPrompts.length}</span>
      </Link>
      <div className="dropdown-menu notifications dropdown-menu-end ">
        <div className="topnav-dropdown-header">
          {drugColumns.length > 0 &&
          <p className="text-primary-emphasis">Do you have this medication?</p>
          }
          <table className="table dashboard-table mb-4">
            <tbody>
            {drugColumns}
            </tbody>
          </table>
          {orderColumns.length > 0 &&
          <p className="mb-0 text-primary-emphasis">You have received new patients (Accept or Reject).</p>
          }
          <table className="table dashboard-table mt-3">
            <tbody>
            {orderColumns}
            </tbody>
          </table>
        </div>
      </div>
    </li>
    {/* /Notifications */}
  </>
  
  );
}

export default Notification;
