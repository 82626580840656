import React, {useEffect, useState} from "react";
import {Form, Input, Button, Typography, Alert} from 'antd';
import {LoadingOutlined, MailOutlined, UserOutlined} from '@ant-design/icons';
import loginBanner from "../../app/assets/images/login-banner.png";
import { Link, useHistory } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";
import api from '../../api';

const ForgotPassword = (props) => {
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(null);

  const onSubmit = (values) => {
    setSaving(true);
    api.post('/users/reset_password.json', {user: values})
        .then((res)=>{
          if(res.status === 200) {
          }
          setSaving(false);
          setErrors("Reset password request succeeded. Please check your email and continue.");
        })
        .catch((error) => {
          setErrors("Reset password request failed. Please check your email address.");
          setSaving(false);
        })
  }

  useEffect(() => {
    document.body.classList.add("account-page");

    return () => document.body.classList.remove("account-page");
  }, []);

  return (
    <>
      <Header {...props} />
      <>
        {/* Page Content */}
        <div className="content top-space">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                {/* Account Content */}
                <div className="account-content">
                  <div className="row align-items-center justify-content-center">
                    {/*<div className="col-md-7 col-lg-6 login-left">*/}
                    {/*  <img*/}
                    {/*    src={loginBanner}*/}
                    {/*    className="img-fluid"*/}
                    {/*    alt="Login Banner"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className="col-md-12 col-lg-6 login-right">
                      <div className="login-header">
                        <h3>Forgot Password?</h3>
                        <p className="small text-muted">
                          Enter your email to get a password reset link
                        </p>
                      </div>
                      {/* Forgot Password Form */}
                      <Form
                          name="forgot_password"
                          onFinish={onSubmit}
                      >
                        {errors &&
                        <Alert message={errors} type="success" style={{marginBottom: 20, fontWeight: 'bold', textAlign: "left"}} />
                        }

                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: 'Please input your Email!' }]}
                        >
                          <Input size={"large"} prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                        </Form.Item>

                        <Form.Item>
                          <Button
                              size={"large"}
                              type="primary" htmlType="submit" className="w-100 login-btn"
                              icon={saving ? <LoadingOutlined /> : null}>
                            Reset Password
                          </Button>
                        </Form.Item>
                      </Form>
                      {/*<form action={`${config}/home`}>*/}
                      {/*  <div className="form-group form-focus">*/}
                      {/*    <input*/}
                      {/*      type="email"*/}
                      {/*      className="form-control floating"*/}
                      {/*    />*/}
                      {/*    <label className="focus-label">Email</label>*/}
                      {/*  </div>*/}
                      {/*  <div className="text-end">*/}
                      {/*    <Link className="forgot-link" to="/login">*/}
                      {/*      Remember your password?*/}
                      {/*    </Link>*/}
                      {/*  </div>*/}
                      {/*  <Link to="/login"*/}
                      {/*    className="btn btn-primary w-100 btn-lg login-btn"*/}
                      {/*    */}
                      {/*  >*/}
                      {/*    Reset Password*/}
                      {/*  </Link>*/}
                      {/*</form>*/}
                      {/* /Forgot Password Form */}
                    </div>
                  </div>
                </div>
                {/* /Account Content */}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </>

      <Footer {...props} />
    </>
  );
};

export default ForgotPassword;
