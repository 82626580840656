/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, {useEffect, useRef, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import {Button, Modal, Avatar} from "antd";
import {
  UserOutlined,
  CheckCircleOutlined,
  EyeOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
const { confirm } = Modal;
var isToday = require("dayjs/plugin/isToday");

dayjs.extend(isToday);

const RxOrder = ({order, accept, decline}) => {
  const history = useHistory();

  useEffect(() => {
  }, []);

  return (
      <tr key={order.id}>
        <td>
          <div className="patient-info-profile">
            {/*<Link*/}
            {/*    to="/doctor/appointments"*/}
            {/*    className="table-avatar"*/}
            {/*>*/}
            <Avatar icon={<UserOutlined/>}/>
            {/*</Link>*/}
            <div className="patient-name-info">
              <span>{`REF#${10000+order.id}`}</span>
              <h5>
                {/*<Link to="/doctor/appointments">*/}
                {order.patient.first_name + ' ' + order.patient.last_name}
                {/*</Link>*/}
              </h5>
            </div>
          </div>
        </td>
        <td>
          <div className="appointment-date-created">
            <h6>{dayjs(order.created_at).format('MMM D, YYYY h:mm A')}</h6>
            <span className={`badge table-badge badge-${order.accepted ? (order.rx_search_transaction ? 'success' : 'danger' ) : (order.declined ? 'info' : (order.other_rx_search_transaction ? 'danger' : 'warning'))}`}>
              {`${order.accepted ? (order.rx_search_transaction ? 'accepted' : 'expired' ) : (order.declined ? 'declined' : (order.other_rx_search_transaction ? 'expired' : 'requested'))}`.toUpperCase()}
            </span>
          </div>
        </td>
        <td>
          {
            order.accepted && order.rx_search_transaction &&
            <Button type="default"
                    onClick={() => history.push('/accounts/pharmacies/rx-transactions/' + order.rx_search_transaction.id)}>
              View Transaction
            </Button>
          }
          { !order.accepted && !order.declined && !order.other_rx_search_transaction &&
          <div className="apponiment-actions d-flex align-items-center">
            <Button className="text-success me-2" type="link" onClick={() => {
              confirm({
                title: 'Accept the Rx request!',
                icon: <CheckCircleOutlined />,
                content: 'Are you sure you want to accept the Rx request?',
                okText: 'Accept Request',
                okType: 'primary',
                cancelText: 'Cancel',
                onOk() {
                  accept(order.id)
                },
              });
            }}>
              <i className="fa-solid fa-check" />
            </Button>
            <Button type="link" className="text-danger" onClick={() => {
              confirm({
                title: 'Decline the Rx request!',
                icon: <CloseCircleOutlined />,
                content: 'Are you sure you want to decline the Rx request?',
                okText: 'Decline Request',
                okType: 'primary',
                cancelText: 'Cancel',
                onOk() {
                  decline(order.id)
                },
              });
            }}>
              <i className="fa-solid fa-xmark" />
            </Button>
          </div>
          }
        </td>
      </tr>
  );
};

export default RxOrder;
