import React from "react";
import { Link } from "react-router-dom";
import { doctorprofileimg } from "../../imagepath";
import Select from 'react-select'
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const DoctorSidebar = ({pendingRequests, pendingTransactions}) => {
  const authUser = useAuthUser();
  let pathnames = window.location.pathname;

  return (
    <>
      {/* Profile Sidebar */}
      <div className="profile-sidebar doctor-sidebar profile-sidebar-new">
        <div className="widget-profile pro-widget-content">
          <div className="profile-info-widget">
            <Link to="/accounts/pharmacies/dashboard" className="booking-doc-img">
              <img
                src={doctorprofileimg}
                alt="User Image"
              />
            </Link>
            <div className="profile-det-info">
              <h3>
                {authUser.pharmacy.first_name} {authUser.pharmacy.last_name}
              </h3>
              <div className="patient-details">
                <h5 className="mb-0">
                  {authUser.pharmacy.name}
                </h5>
              </div>
              <span className="badge doctor-role-badge">
                <i className="fa-solid fa-circle" />
                Pharmacist
              </span>
            </div>
          </div>
        </div>
        <div className="dashboard-widget">
          <nav className="dashboard-menu">
            <ul>
              <li className={pathnames.includes("/accounts/pharmacies/dashboard") ? "active" : ""}>

                <Link to="/accounts/pharmacies/dashboard">
                  <i className="fa-solid fa-shapes me-2" />
                  <span>Dashboard</span>
                  {pendingRequests > 0 &&
                  <small className="unread-msg">{pendingRequests}</small>
                  }
                </Link>
              </li>
              <li className={pathnames.includes("/accounts/pharmacies/rx-transactions") ? "active" : ""}>
                <Link to="/accounts/pharmacies/rx-transactions">
                  <i className="fa-solid fa-sack-dollar me-2" />
                  <span>Transactions</span>
                  {pendingTransactions > 0 &&
                  <small className="unread-msg">{pendingTransactions}</small>
                  }
                </Link>
              </li>
              <li className={pathnames.includes("/accounts/pharmacies/medications") ? "active" : ""}>
                <Link to="/accounts/pharmacies/medications">
                  <i className="fa-solid fa-box-open me-2" />
                  <span>Medications</span>
                  {/*<small className="unread-msg"></small>*/}
                </Link>
              </li>

              {/*<li className={pathnames.includes('/doctor/invoices') ? 'active' : ''}>*/}
              {/*  <Link to="/doctor/invoices">*/}
              {/*    <i className="fa-solid fa-file-lines me-2" />*/}
              {/*    <span>Transactions</span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li className={pathnames.includes('/accounts/pharmacies/profile-setting') ? 'active' : ''}>
                <Link to="/accounts/pharmacies/profile-setting">
                  <i className="fa-solid fa-user-pen me-2" />
                  <span>Profile Settings</span>
                </Link>
              </li>
              <li className={pathnames.includes('/accounts/pharmacies/contact-admin') ? 'active' : ''}>
                <Link to="/accounts/pharmacies/contact-admin">
                  <i className="fa-solid fa-question-circle me-2" />
                    <span>Contact Admin</span>
                </Link>
              </li>
              <li className={pathnames.includes('/accounts/pharmacies/change-password') ? 'active' : ''}>
                <Link to="/accounts/pharmacies/change-password">
                  <i className="fa-solid fa-key me-2" />
                  <span>Change Password</span>
                </Link>
              </li>
              <li className={pathnames.includes("/login") ? 'active' : ''}>
                <Link to="/login">
                  {/*<i className="fa-solid fa-calendar-check me-2" />*/}
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {/* /Profile Sidebar */}
    </>
  );
};

export default DoctorSidebar;
