/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../app/assets/images/logo.png";
import logosvg from "../app/assets/images/logo.svg";
import { useEffect } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import {
  Browser_categorie,
  doctorprofileimg,
  doctordashboardprofile02,
  logo_03,
  logo_15,
  logo_svg,
  logo_white,
} from "./imagepath";

import Notification from "./patients/dashboard/notification";
import { IMG07 } from "../components/patients/doctorprofile/img";
import NavLinks from "./nav";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import api from '../api';
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const authUser = useAuthUser();

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  const config = "";

  //mobile menu
  const [change, setChange] = useState(false);
  const [isSideMenu, setSideMenu] = useState("");
  const [isSideMenuone, setSideMenuone] = useState("");
  const [isSideMenutwo, setSideMenutwo] = useState("");
  const [isSideSearch, setSideSearch] = useState("");
  const [isSidebooking, setSideBooking] = useState("");
  const [button, setButton] = useState(true);
  const [navbar, setNavbar] = useState(false);
  const [isSideMenuthree, setSideMenuthree] = useState("");
  const [isSideMenufour, setSideMenufour] = useState("");
  const [sideMenufive, setSideMenufive] = useState("");
  const [menu, setMenu] = useState(false);
  const signOut = useSignOut();

  // const [menu1, setMenu1] = useState(false);
  const toggleSidebarthree = (value) => {
    setSideMenuthree(value);
  };
  const toggleSidebar = (value) => {
    setSideMenu(value);
  };
  const toggleSidebarfour = (value) => {
    setSideMenufour(value);
  };
  const toggleSidebarfive = (value) => {
    setSideMenufive(value);
  };
  const toggleSidebarone = (value) => {
    setSideMenuone(value);
  };
  const toggleSidebartwo = (value) => {
    setSideMenutwo(value);
  };
  const toggleSidebarsearch = (value) => {
    setSideSearch(value);
  };
  const toggleSidebarbooking = (value) => {
    setSideBooking(value);
  };

  // const mobilemenus = () => {
  //   setMenu(!true);
  // };

  // Rest of your code that uses pathnames

  let pathnames = window.location.pathname;

  // const [active, setActive] = useState(false);
  const url = pathnames.split("/").slice(0, -1).join("/");

  const onHandleMobileMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.add("menu-opened");
  };

  const onhandleCloseMenu = () => {
    var root = document.getElementsByTagName("html")[0];
    root.classList.remove("menu-opened");
  };

  const onLogout = () => {
    api.delete('/users/logout.json', )
        .then((res)=> {
          console.log(res);
        });

    signOut();
  };

  //nav transparent

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener("resize", showButton);

  const changeBackground = () => {
    if (window.scrollY >= 95) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      {!pathnames.includes("home1") && (
        <header
          className={`header ${
            pathnames.includes("/index-11")
              ? "header-fixed header-fourteen header-sixteen"
              : "" || pathnames.includes("/index-10")
              ? "header-fixed header-fourteen header-fifteen"
              : "" || pathnames.includes("/index-9")
              ? "header-fixed header-fourteen"
              : "" || pathnames.includes("/index-8")
              ? "header-fixed header-fourteen header-twelve header-thirteen"
              : "" || pathnames.includes("/index-7")
              ? "header-fixed header-fourteen header-twelve"
              : "" || pathnames.includes("/index-6")
              ? "header-trans header-eleven"
              : "" || pathnames.includes("/index-4")
              ? "header-trans custom"
              : "" || pathnames.includes("/index-5")
              ? "header header-fixed header-ten"
              : "" || pathnames.includes("home")
              ? "header-trans header-two"
              : "" || pathnames.includes("/index-13")
              ? "header header-custom header-fixed header-ten home-care-header"
              : "" || pathnames.includes("/pharmacy-index")
              ? "header header-one"
              : "header-fixed header-one header-space header-custom"
          } 
           ${isScrolled ? 'pharmacy-header' : ''} `}
          style={
            pathnames.includes("/index-6") && navbar
              ? { background: "rgb(30, 93, 146)" }
              : { background: "" } && pathnames.includes("/index-10") && navbar
              ? { background: "rgb(255, 255, 255)" }
              : { background: "" } && pathnames.includes("/index-11") && navbar
              ? { background: "rgb(255, 255, 255)" }
              : { background: "" } && pathnames.includes("/index-4") && navbar
              ? { background: "rgb(43, 108, 203)" }
              : { background: "" } && pathnames.includes("/index-9") && navbar
              ? { background: "rgb(43, 108, 203)" }
              : { background: "" } && pathnames.includes("/index-2") && navbar
              ? { background: "rgb(255, 255, 255)" }
              : { background: "" }
          }
        >
          <div className="container">
            <nav
              className={`navbar navbar-expand-lg header-nav ${
                pathnames.includes("home1") ? "nav-transparent" : ""
              }`}
            >
              <div className="navbar-header">
                <Link
                  to="#0"
                  id="mobile_btn"
                  onClick={() => onHandleMobileMenu()}
                >
                  <span className="bar-icon">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </Link>
                <Link to="/" className="navbar-brand logo">
                  {pathnames.includes("/index-5") ? (
                    <img src={logo_white} className="img-fluid" alt="Logo" />
                  ) : pathnames.includes(
                      "/pharmacy/pharmacy-index"
                    ) ? (
                    <div className="browse-categorie">
                      <div className="dropdown categorie-dropdown">
                        <Link
                          to="#"
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          <img src={Browser_categorie} alt /> Browse Categories
                        </Link>
                        <div className="dropdown-menu">
                          <Link className="dropdown-item" to="#">
                            Ayush
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Covid Essentials
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Devices
                          </Link>
                          <Link className="dropdown-item" to="#">
                            Glucometers
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={
                        pathnames === "/index-6" ||
                        pathnames === "/index-4"
                          ? logosvg
                          : pathnames === "/index-11"
                          ? logo_15
                          : pathnames === "/index-10"
                          ? logo_15
                          : pathnames === "/index-9"
                          ? logo_03
                          : pathnames === "/index-7"
                          ? logo_svg
                          : pathnames == "/index-13"
                          ? logo_white
                          : logo
                      }
                      className="img-fluid"
                      alt="Logo"
                    />
                  )}
                </Link>
              </div>
              <div className="main-menu-wrapper">
                <div className="menu-header">
                  <Link to="/" className="menu-logo">
                    <img src={logo} className="img-fluid" alt="Logo" />
                  </Link>
                  <Link
                    to="#0"
                    id="menu_close"
                    className="menu-close"
                    onClick={() => onhandleCloseMenu()}
                  >
                    <i className="fas fa-times"></i>
                  </Link>
                </div>

                <ul
                  className={`main-nav ${
                    pathnames.includes("home4") ? "white-font" : ""
                  }`}
                >
                  <NavLinks/>
                </ul>
              </div>
              {authUser && authUser.patient && authUser.patient.id ? (
                <>
                  <ul className="nav header-navbar-rht">
                    <Notification />
                    <li className="nav-item dropdown has-arrow logged-item">
                      <Link
                        to="#"
                        className="dropdown-toggle nav-link"
                        data-bs-toggle="dropdown"
                      >
                        <span className="user-img">
                          <img
                            className="rounded-circle"
                            src={IMG07}
                            width="31"
                            alt="Darren Elder"
                          />
                        </span>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <div className="user-header">
                          <div className="avatar avatar-sm">
                            <img
                              src={IMG07}
                              alt="User Image"
                              className="avatar-img rounded-circle"
                            />
                          </div>
                          <div className="user-text">
                            <h6>{authUser.patient.first_name} {authUser.patient.last_name}</h6>
                            <p className="text-muted mb-0">Patient</p>
                          </div>
                        </div>
                        <Link className="dropdown-item" to="/accounts/patients/dashboard">
                          Dashboard
                        </Link>
                        {/*<Link className="dropdown-item" to="/accounts/patients/profile">*/}
                        {/*  Profile Settings*/}
                        {/*</Link>*/}
                        <Link className="dropdown-item" to="/login" onClick={onLogout}>
                          Logout
                        </Link>
                      </div>
                    </li>
                  </ul>
                </>
              ) : authUser && authUser.pharmacy && authUser.pharmacy.id ? (
                <ul className="nav header-navbar-rht">
                  <Notification />
                  <li className="nav-item dropdown has-arrow logged-item">
                    <Link
                      to="#"
                      className="dropdown-toggle nav-link"
                      data-bs-toggle="dropdown"
                    >
                      <span className="user-img">
                        <img
                          className="rounded-circle"
                          src={doctorprofileimg}
                          width="31"
                          alt="Darren Elder"
                        />
                      </span>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end">
                      <div className="user-header">
                        <div className="avatar avatar-sm">
                          <img
                            src={doctorprofileimg}
                            alt="User Image"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                        <div className="user-text">
                          <h6>{authUser.pharmacy.first_name} {authUser.pharmacy.last_name}</h6>
                          <p className="text-muted mb-0">Available</p>
                        </div>
                      </div>
                      <Link className="dropdown-item" to="/accounts/pharmacies/dashboard">
                        Dashboard
                      </Link>
                      {/*<Link className="dropdown-item" to="/accounts/pharmacies/profile-setting">*/}
                      {/*  Profile Settings*/}
                      {/*</Link>*/}
                      <Link className="dropdown-item" to="/login" onClick={signOut}>
                        Logout
                      </Link>
                    </div>
                  </li>
                </ul>
              ) : authUser && authUser.role === 'staff' ? (
                  <ul className="nav header-navbar-rht">
                    <Notification />
                    <li className="nav-item dropdown has-arrow logged-item">
                      <Link
                          to="#"
                          className="dropdown-toggle nav-link"
                          data-bs-toggle="dropdown"
                      >
                      <span className="user-img">
                        <img
                            className="rounded-circle"
                            src={doctordashboardprofile02}
                            width="31"
                            alt="Customer Support"
                        />
                      </span>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-end">
                        <div className="user-header">
                          <div className="user-text">
                            <h6>Customer Support</h6>
                          </div>
                        </div>
                        <Link className="dropdown-item" to="/accounts/staffs/dashboard">
                          Dashboard
                        </Link>
                        <Link className="dropdown-item" to="/login" onClick={signOut}>
                          Logout
                        </Link>
                      </div>
                    </li>
                  </ul>
              ) : null}

            </nav>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
