import React, {useEffect, useState} from 'react'
import axios from "axios";

export const RxDrugName = ({rxcui}) => {
    const [title, setTitle] = useState("");
    useEffect(() => {
        axios.get('https://rxnav.nlm.nih.gov/REST/RxTerms/rxcui/' + rxcui + '/allinfo.json')
            .then((resp) => {
                setTitle(resp.data.rxtermsProperties?.displayName);
            })
    }, [rxcui]);

    return <>
        {title}
    </>
};

export const RxDrugDosage = ({rxcui}) => {
    const [title, setTitle] = useState("");
    useEffect(() => {
        axios.get('https://rxnav.nlm.nih.gov/REST/RxTerms/rxcui/' + rxcui + '/allinfo.json')
            .then((resp) => {
                setTitle(resp.data.rxtermsProperties?.strength);
            })
    }, [rxcui]);

    return <>
        {title}
    </>
};

export const RxDrugForm = ({rxcui}) => {
    const [title, setTitle] = useState("");
    useEffect(() => {
        axios.get('https://rxnav.nlm.nih.gov/REST/RxTerms/rxcui/' + rxcui + '/allinfo.json')
            .then((resp) => {
                setTitle(resp.data.rxtermsProperties?.rxnormDoseForm);
            })
    }, [rxcui]);

    return <>
        {title}
    </>
};
