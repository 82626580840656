import React, {useEffect, useRef, useState} from "react";
import DoctorSidebar from "../sidebar";
import Header from "../../header";
import DoctorFooter from "../../common/doctorFooter";
import { Link, useHistory } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import RxOrders from '../rxorders'
import api from '../../../api';
import dayjs from "dayjs";
import {Tag, Avatar, Button, Modal} from "antd";
import {UserOutlined, EyeOutlined, CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons';
const { confirm } = Modal;
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

const DoctorDashboard = (props) => {
  const history = useHistory();
  const authUser = useAuthUser();
  const [orders, setOrders] = useState([]);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    fetchOrders();
    fetchTransactions();
  }, []);

  const fetchOrders = () => {
    api.get(`/pharmacies/${authUser.pharmacy.id}/rx_search_orders.json`)
        .then((res) => {
          if (res.data) {
            const data = res.data.filter((order) => order.rx_drug)
            setOrders(data)
            console.log(data)
          }
        })
        .catch(err => console.log(err));
  }
  const fetchTransactions = () => {
    api.get(`/pharmacies/${authUser.pharmacy.id}/rx_search_transactions.json`)
        .then((res) => {
          console.log(res.data)
          if (res.data) {
            setTransactions(res.data)
          }
        })
        .catch(err => console.log(err));
  }

  return (
    <div className="col-lg-8 col-xl-9">
      <div className="row">
        <div className="col-xl-4 d-flex">
          <div className="dashboard-box-col w-100">
            <div className="dashboard-widget-box">
              <div className="dashboard-content-info">
                <h6>Total Requests</h6>
                <h4>{orders.length}</h4>
                <span className="text-success">
                  <i className="fa-solid fa-arrow-up" />
                  15% From Last Week
                </span>
              </div>
              <div className="dashboard-widget-icon">
                <span className="dash-icon-box">
                  <i className="fa-solid fa-user-injured" />
                </span>
              </div>
            </div>
            <div className="dashboard-widget-box">
              <div className="dashboard-content-info">
                <h6>Requests Today</h6>
                <h4>{orders.filter((order) => dayjs(order.createdAt).isToday()).length}</h4>
                <span className="text-danger">
                  <i className="fa-solid fa-arrow-up" />
                  15% From Yesterday
                </span>
              </div>
              <div className="dashboard-widget-icon">
                <span className="dash-icon-box">
                  <i className="fa-solid fa-user-clock" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8 d-flex">
          <div className="dashboard-card w-100">
            <div className="dashboard-card-head">
              <div className="header-title">
                <h5>Patient Orders</h5>
              </div>
              <div className="dropdown header-dropdown">
                <Link
                  className="dropdown-toggle nav-tog"
                  data-bs-toggle="dropdown"
                  to="#"
                >
                  Last 7 Days
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                  <Link to="#" className="dropdown-item">
                    Today
                  </Link>
                  <Link to="#" className="dropdown-item">
                    This Month
                  </Link>
                  <Link to="#" className="dropdown-item">
                    Last 7 Days
                  </Link>
                </div>
              </div>
            </div>
            <div className="dashboard-card-body">
              <div className="table-responsive">
                <RxOrders/>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="col-xl-5 d-flex">*/}
        {/*  <div className="dashboard-chart-col w-100">*/}
        {/*    <div className="dashboard-card w-100">*/}
        {/*      <div className="dashboard-card-head border-0">*/}
        {/*        <div className="header-title">*/}
        {/*          <h5>Weekly Overview</h5>*/}
        {/*        </div>*/}
        {/*        <div className="chart-create-date">*/}
        {/*          <h6>Mar 14 - Mar 21</h6>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="dashboard-card-body">*/}
        {/*        <div className="chart-tab">*/}
        {/*          <ul*/}
        {/*            className="nav nav-pills product-licence-tab"*/}
        {/*            id="pills-tab2"*/}
        {/*            role="tablist"*/}
        {/*          >*/}
        {/*            <li className="nav-item" role="presentation">*/}
        {/*              <button*/}
        {/*                className="nav-link active"*/}
        {/*                id="pills-revenue-tab"*/}
        {/*                data-bs-toggle="pill"*/}
        {/*                data-bs-target="#pills-revenue"*/}
        {/*                type="button"*/}
        {/*                role="tab"*/}
        {/*                aria-controls="pills-revenue"*/}
        {/*                aria-selected="false"*/}
        {/*              >*/}
        {/*                Revenue*/}
        {/*              </button>*/}
        {/*            </li>*/}
        {/*            <li className="nav-item" role="presentation">*/}
        {/*              <button*/}
        {/*                className="nav-link"*/}
        {/*                id="pills-appointment-tab"*/}
        {/*                data-bs-toggle="pill"*/}
        {/*                data-bs-target="#pills-appointment"*/}
        {/*                type="button"*/}
        {/*                role="tab"*/}
        {/*                aria-controls="pills-appointment"*/}
        {/*                aria-selected="true"*/}
        {/*              >*/}
        {/*                Appointments*/}
        {/*              </button>*/}
        {/*            </li>*/}
        {/*          </ul>*/}
        {/*          <div*/}
        {/*            className="tab-content w-100"*/}
        {/*            id="v-pills-tabContent"*/}
        {/*          >*/}
        {/*            <div*/}
        {/*              className="tab-pane fade show active"*/}
        {/*              id="pills-revenue"*/}
        {/*              role="tabpanel"*/}
        {/*              aria-labelledby="pills-revenue-tab"*/}
        {/*            >*/}
        {/*            <div ref={chartRef1} id="revenue-chart" />*/}
        {/*            </div>*/}
        {/*            <div*/}
        {/*              className="tab-pane fade"*/}
        {/*              id="pills-appointment"*/}
        {/*              role="tabpanel"*/}
        {/*              aria-labelledby="pills-appointment-tab"*/}
        {/*            >*/}
        {/*            <div ref={chartRef} id="appointment-chart" />*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="dashboard-card w-100">*/}
        {/*      <div className="dashboard-card-head">*/}
        {/*        <div className="header-title">*/}
        {/*          <h5>Recent Patients</h5>*/}
        {/*        </div>*/}
        {/*        <div className="card-view-link">*/}
        {/*          <Link to="/doctor/my-patients">View All</Link>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="dashboard-card-body">*/}
        {/*        <div className="d-flex recent-patient-grid-boxes">*/}
        {/*          <div className="recent-patient-grid">*/}
        {/*            <Link to="pages/patient-details" className="patient-img">*/}
        {/*              <img*/}
        {/*                src={doctordashboardprofile01}*/}
        {/*                alt="Img"*/}
        {/*              />*/}
        {/*            </Link>*/}
        {/*            <h5>*/}
        {/*              <Link to="pages/patient-details">Adrian Marshall</Link>*/}
        {/*            </h5>*/}
        {/*            <span>Patient ID :&nbsp;P0001</span>*/}
        {/*            <div className="date-info">*/}
        {/*              <p>Last Appointment 15 Mar 2024</p>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*          <div className="recent-patient-grid">*/}
        {/*            <Link to="pages/patient-details" className="patient-img">*/}
        {/*              <img*/}
        {/*                src={doctordashboardprofile02}*/}
        {/*                alt="Img"*/}
        {/*              />*/}
        {/*            </Link>*/}
        {/*            <h5>*/}
        {/*              <Link to="pages/patient-details">Kelly Stevens</Link>*/}
        {/*            </h5>*/}
        {/*            <span>Patient ID :&nbsp;P0002</span>*/}
        {/*            <div className="date-info">*/}
        {/*              <p>Last Appointment 13 Mar 2024</p>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="col-xl-7 d-flex">*/}
        {/*  <div className="dashboard-main-col w-100">*/}
        {/*    <div className="upcoming-appointment-card">*/}
        {/*      <div className="title-card">*/}
        {/*        <h5>Upcoming Appointment</h5>*/}
        {/*      </div>*/}
        {/*      <div className="upcoming-patient-info">*/}
        {/*        <div className="info-details">*/}
        {/*          <span className="img-avatar">*/}
        {/*            <img*/}
        {/*              src={doctordashboardprofile01}*/}
        {/*              alt="Img"*/}
        {/*            />*/}
        {/*          </span>*/}
        {/*          <div className="name-info">*/}
        {/*            <span>#Apt0001</span>*/}
        {/*            <h6>Adrian Marshall</h6>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div className="date-details">*/}
        {/*          <span>General visit</span>*/}
        {/*          <h6>Today, 10:45 AM</h6>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="appointment-card-footer">*/}
        {/*        <h5>*/}
        {/*          <i className="fa-solid fa-video" />*/}
        {/*          Video Appointment*/}
        {/*        </h5>*/}
        {/*        <div className="btn-appointments">*/}
        {/*          <Link to="/doctor/chat-doctor" className="btn">*/}
        {/*            Chat Now*/}
        {/*          </Link>*/}
        {/*          <Link to="/doctor/doctor-appointment-start" className="btn">*/}
        {/*            Start Appointment*/}
        {/*          </Link>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="dashboard-card w-100">*/}
        {/*      <div className="dashboard-card-head">*/}
        {/*        <div className="header-title">*/}
        {/*          <h5>Recent Invoices</h5>*/}
        {/*        </div>*/}
        {/*        <div className="card-view-link">*/}
        {/*          <Link to="/doctor/invoices">View All</Link>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="dashboard-card-body">*/}
        {/*        <div className="table-responsive">*/}
        {/*          <table className="table dashboard-table">*/}
        {/*            <tbody>*/}
        {/*              <tr>*/}
        {/*                <td>*/}
        {/*                  <div className="patient-info-profile">*/}
        {/*                    <Link*/}
        {/*                      to="/doctor/invoices"*/}
        {/*                      className="table-avatar"*/}
        {/*                    >*/}
        {/*                      <img*/}
        {/*                        src={doctordashboardprofile01}*/}
        {/*                        alt="Img"*/}
        {/*                      />*/}
        {/*                    </Link>*/}
        {/*                    <div className="patient-name-info">*/}
        {/*                      <h5>*/}
        {/*                        <Link to="/doctor/invoices">Adrian</Link>*/}
        {/*                      </h5>*/}
        {/*                      <span>#Apt0001</span>*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="appointment-date-created">*/}
        {/*                    <span className="paid-text">Amount</span>*/}
        {/*                    <h6>$450</h6>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="appointment-date-created">*/}
        {/*                    <span className="paid-text">Paid On</span>*/}
        {/*                    <h6>11 Nov 2024</h6>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="apponiment-view d-flex align-items-center">*/}
        {/*                    <Link to="/pages/invoice-view">*/}
        {/*                      <i className="fa-solid fa-eye" />*/}
        {/*                    </Link>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*              </tr>*/}
        {/*              <tr>*/}
        {/*                <td>*/}
        {/*                  <div className="patient-info-profile">*/}
        {/*                    <Link to="#" className="table-avatar">*/}
        {/*                      <img*/}
        {/*                        src={doctordashboardprofile02}*/}
        {/*                        alt="Img"*/}
        {/*                      />*/}
        {/*                    </Link>*/}
        {/*                    <div className="patient-name-info">*/}
        {/*                      <h5>*/}
        {/*                        <Link to="#">Kelly</Link>*/}
        {/*                      </h5>*/}
        {/*                      <span>#Apt0002</span>*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="appointment-date-created">*/}
        {/*                    <span className="paid-text">Paid On</span>*/}
        {/*                    <h6>10 Nov 2024</h6>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="appointment-date-created">*/}
        {/*                    <span className="paid-text">Amount</span>*/}
        {/*                    <h6>$500</h6>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="apponiment-view d-flex align-items-center">*/}
        {/*                    <Link to="#">*/}
        {/*                      <i className="fa-solid fa-eye" />*/}
        {/*                    </Link>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*              </tr>*/}
        {/*              <tr>*/}
        {/*                <td>*/}
        {/*                  <div className="patient-info-profile">*/}
        {/*                    <Link to="#" className="table-avatar">*/}
        {/*                      <img*/}
        {/*                        src={doctordashboardprofile3}*/}
        {/*                        alt="Img"*/}
        {/*                      />*/}
        {/*                    </Link>*/}
        {/*                    <div className="patient-name-info">*/}
        {/*                      <h5>*/}
        {/*                        <Link to="#">Samuel</Link>*/}
        {/*                      </h5>*/}
        {/*                      <span>#Apt0003</span>*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="appointment-date-created">*/}
        {/*                    <span className="paid-text">Paid On</span>*/}
        {/*                    <h6>03 Nov 2024</h6>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="appointment-date-created">*/}
        {/*                    <span className="paid-text">Amount</span>*/}
        {/*                    <h6>$320</h6>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="apponiment-view d-flex align-items-center">*/}
        {/*                    <Link to="#">*/}
        {/*                      <i className="fa-solid fa-eye" />*/}
        {/*                    </Link>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*              </tr>*/}
        {/*              <tr>*/}
        {/*                <td>*/}
        {/*                  <div className="patient-info-profile">*/}
        {/*                    <Link to="#" className="table-avatar">*/}
        {/*                      <img*/}
        {/*                        src={doctordashboardprofile04}*/}
        {/*                        alt="Img"*/}
        {/*                      />*/}
        {/*                    </Link>*/}
        {/*                    <div className="patient-name-info">*/}
        {/*                      <h5>*/}
        {/*                        <Link to="#">Catherine</Link>*/}
        {/*                      </h5>*/}
        {/*                      <span>#Apt0004</span>*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="appointment-date-created">*/}
        {/*                    <span className="paid-text">Paid On</span>*/}
        {/*                    <h6>01 Nov 2024</h6>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="appointment-date-created">*/}
        {/*                    <span className="paid-text">Amount</span>*/}
        {/*                    <h6>$240</h6>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="apponiment-view d-flex align-items-center">*/}
        {/*                    <Link to="#">*/}
        {/*                      <i className="fa-solid fa-eye" />*/}
        {/*                    </Link>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*              </tr>*/}
        {/*              <tr>*/}
        {/*                <td>*/}
        {/*                  <div className="patient-info-profile">*/}
        {/*                    <Link to="#" className="table-avatar">*/}
        {/*                      <img*/}
        {/*                        src={doctordashboardprofile05}*/}
        {/*                        alt="Img"*/}
        {/*                      />*/}
        {/*                    </Link>*/}
        {/*                    <div className="patient-name-info">*/}
        {/*                      <h5>*/}
        {/*                        <Link to="#">Robert</Link>*/}
        {/*                      </h5>*/}
        {/*                      <span>#Apt0005</span>*/}
        {/*                    </div>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="appointment-date-created">*/}
        {/*                    <span className="paid-text">Paid On</span>*/}
        {/*                    <h6>28 Oct 2024</h6>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="appointment-date-created">*/}
        {/*                    <span className="paid-text">Amount</span>*/}
        {/*                    <h6>$380</h6>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*                <td>*/}
        {/*                  <div className="apponiment-view d-flex align-items-center">*/}
        {/*                    <Link to="#">*/}
        {/*                      <i className="fa-solid fa-eye" />*/}
        {/*                    </Link>*/}
        {/*                  </div>*/}
        {/*                </td>*/}
        {/*              </tr>*/}
        {/*            </tbody>*/}
        {/*          </table>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="col-xl-7 d-flex">*/}
        {/*  <div className="dashboard-card w-100">*/}
        {/*    <div className="dashboard-card-head">*/}
        {/*      <div className="header-title">*/}
        {/*        <h5>Notifications</h5>*/}
        {/*      </div>*/}
        {/*      <div className="card-view-link">*/}
        {/*        <Link to="#">View All</Link>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="dashboard-card-body">*/}
        {/*      <div className="table-responsive">*/}
        {/*        <table className="table dashboard-table">*/}
        {/*          <tbody>*/}
        {/*            <tr>*/}
        {/*              <td>*/}
        {/*                <div className="table-noti-info">*/}
        {/*                  <div className="table-noti-icon color-violet">*/}
        {/*                    <i className="fa-solid fa-bell" />*/}
        {/*                  </div>*/}
        {/*                  <div className="table-noti-message">*/}
        {/*                    <h6>*/}
        {/*                      <Link to="#">*/}
        {/*                        Booking Confirmed on{" "}*/}
        {/*                        <span> 21 Mar 2024 </span> 10:30 AM*/}
        {/*                      </Link>*/}
        {/*                    </h6>*/}
        {/*                    <span className="message-time">Just Now</span>*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </td>*/}
        {/*            </tr>*/}
        {/*            <tr>*/}
        {/*              <td>*/}
        {/*                <div className="table-noti-info">*/}
        {/*                  <div className="table-noti-icon color-blue">*/}
        {/*                    <i className="fa-solid fa-star" />*/}
        {/*                  </div>*/}
        {/*                  <div className="table-noti-message">*/}
        {/*                    <h6>*/}
        {/*                      <Link to="#">*/}
        {/*                        You have a <span> New </span> Review for*/}
        {/*                        your Appointment{" "}*/}
        {/*                      </Link>*/}
        {/*                    </h6>*/}
        {/*                    <span className="message-time">5 Days ago</span>*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </td>*/}
        {/*            </tr>*/}
        {/*            <tr>*/}
        {/*              <td>*/}
        {/*                <div className="table-noti-info">*/}
        {/*                  <div className="table-noti-icon color-red">*/}
        {/*                    <i className="fa-solid fa-calendar-check" />*/}
        {/*                  </div>*/}
        {/*                  <div className="table-noti-message">*/}
        {/*                    <h6>*/}
        {/*                      <Link to="#">*/}
        {/*                        You have Appointment with{" "}*/}
        {/*                        <span> Ahmed </span> by 01:20 PM{" "}*/}
        {/*                      </Link>*/}
        {/*                    </h6>*/}
        {/*                    <span className="message-time">12:55 PM</span>*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </td>*/}
        {/*            </tr>*/}
        {/*            <tr>*/}
        {/*              <td>*/}
        {/*                <div className="table-noti-info">*/}
        {/*                  <div className="table-noti-icon color-yellow">*/}
        {/*                    <i className="fa-solid fa-money-bill-1-wave" />*/}
        {/*                  </div>*/}
        {/*                  <div className="table-noti-message">*/}
        {/*                    <h6>*/}
        {/*                      <Link to="#">*/}
        {/*                        Sent an amount of <span> $200 </span> for an*/}
        {/*                        Appointment by 01:20 PM{" "}*/}
        {/*                      </Link>*/}
        {/*                    </h6>*/}
        {/*                    <span className="message-time">2 Days ago</span>*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </td>*/}
        {/*            </tr>*/}
        {/*            <tr>*/}
        {/*              <td>*/}
        {/*                <div className="table-noti-info">*/}
        {/*                  <div className="table-noti-icon color-blue">*/}
        {/*                    <i className="fa-solid fa-star" />*/}
        {/*                  </div>*/}
        {/*                  <div className="table-noti-message">*/}
        {/*                    <h6>*/}
        {/*                      <Link to="#">*/}
        {/*                        You have a <span> New </span> Review for*/}
        {/*                        your Appointment{" "}*/}
        {/*                      </Link>*/}
        {/*                    </h6>*/}
        {/*                    <span className="message-time">5 Days ago</span>*/}
        {/*                  </div>*/}
        {/*                </div>*/}
        {/*              </td>*/}
        {/*            </tr>*/}
        {/*          </tbody>*/}
        {/*        </table>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*<div className="col-xl-5 d-flex">*/}
        {/*  <div className="dashboard-card w-100">*/}
        {/*    <div className="dashboard-card-head">*/}
        {/*      <div className="header-title">*/}
        {/*        <h5>Clinics &amp; Availability</h5>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <div className="dashboard-card-body">*/}
        {/*      <div className="clinic-available">*/}
        {/*        <div className="clinic-head">*/}
        {/*          <div className="clinic-info">*/}
        {/*            <span className="clinic-img">*/}
        {/*              <img*/}
        {/*                src={doctordashboardclient02}*/}
        {/*                alt="Img"*/}
        {/*              />*/}
        {/*            </span>*/}
        {/*            <h6>Sofi’s Clinic</h6>*/}
        {/*          </div>*/}
        {/*          <div className="clinic-charge">*/}
        {/*            <span>$900</span>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div className="available-time">*/}
        {/*          <ul>*/}
        {/*            <li>*/}
        {/*              <span>Tue :</span>*/}
        {/*              07:00 AM - 09:00 PM*/}
        {/*            </li>*/}
        {/*            <li>*/}
        {/*              <span>Wed : </span>*/}
        {/*              07:00 AM - 09:00 PM*/}
        {/*            </li>*/}
        {/*          </ul>*/}
        {/*          <div className="change-time">*/}
        {/*            <Link to="#">Change </Link>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="clinic-available mb-0">*/}
        {/*        <div className="clinic-head">*/}
        {/*          <div className="clinic-info">*/}
        {/*            <span className="clinic-img">*/}
        {/*              <img*/}
        {/*                src={doctordashboardclient01}*/}
        {/*                alt="Img"*/}
        {/*              />*/}
        {/*            </span>*/}
        {/*            <h6>The Family Dentistry Clinic</h6>*/}
        {/*          </div>*/}
        {/*          <div className="clinic-charge">*/}
        {/*            <span>$600</span>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div className="available-time">*/}
        {/*          <ul>*/}
        {/*            <li>*/}
        {/*              <span>Sat :</span>*/}
        {/*              07:00 AM - 09:00 PM*/}
        {/*            </li>*/}
        {/*            <li>*/}
        {/*              <span>Tue : </span>*/}
        {/*              07:00 AM - 09:00 PM*/}
        {/*            </li>*/}
        {/*          </ul>*/}
        {/*          <div className="change-time">*/}
        {/*            <Link to="#">Change </Link>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default DoctorDashboard;
