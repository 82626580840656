/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, {useEffect, useRef, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import api from '../../../api';
import dayjs from "dayjs";
import RxOrder from "./rxorder"

var isToday = require("dayjs/plugin/isToday");

dayjs.extend(isToday);

const RxOrders = (props) => {
  const history = useHistory();
  const authUser = useAuthUser();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = () => {
    api.get(`/pharmacies/${authUser.pharmacy.id}/rx_search_orders.json`)
        .then((res) => {
          if (res.data) {
            const data = res.data.filter((order) => order.rx_drug)
            setOrders(data)
          }
          // setNameOptionsLoading(false);
        })
        .catch(err => console.log(err));
  }

  const acceptOrder = (id) => {
    api.patch('/rx_search_orders/' + id + '.json', {accepted: true, declined: false})
        .then((res) => {
          if (res.data) {
            const data = orders.map((order) => order.id === res.data.id ? res.data : order);
            setOrders(data)
          }
          // setNameOptionsLoading(false);
        })
        .catch(err => console.log(err));
  }

  const declineOrder = (id) => {
    api.patch('/rx_search_orders/' + id + '.json', {declined: true, accepted: false})
        .then((res) => {
          if (res.data) {
            const data = orders.map((order) => order.id === res.data.id ? res.data : order);
            setOrders(data)
          }
          // setNameOptionsLoading(false);
        })
        .catch(err => console.log(err));
  }

  const requestColumns = orders.map((order) => (
      <RxOrder key={order.id} order={order} accept={acceptOrder} decline={declineOrder}/>
  ))

  return (
    <table className="table dashboard-table">
      <tbody>
      {requestColumns}
      </tbody>
    </table>
  );
};

export default RxOrders;
