/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import React, {useEffect, useRef, useState} from "react";
import { Link, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import {Button, Modal, Avatar} from "antd";
import {
  DisconnectOutlined,
  CheckCircleOutlined,
  EyeOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import {RxDrugDosage, RxDrugName, RxDrugForm} from "../../common/drug";
const { confirm } = Modal;
var isToday = require("dayjs/plugin/isToday");

dayjs.extend(isToday);

const DrugPrompt = ({prompt, accept, decline}) => {
  const history = useHistory();

  useEffect(() => {
  }, []);

  return (
      <tr key={prompt.id}>
        <td>
          <div className="patient-info-profile">
            {/*<Link*/}
            {/*    to="/doctor/appointments"*/}
            {/*    className="table-avatar"*/}
            {/*>*/}
            <Avatar icon={<DisconnectOutlined />}/>
            {/*</Link>*/}
            <div className="patient-name-info">
              <span>{`REF#${prompt.rx_drug.rxcui}`}</span>
              <h5>
                <RxDrugName rxcui={prompt.rx_drug.rxcui} />
              </h5>
            </div>
          </div>
        </td>
        <td>
          <div className="appointment-date-created">
            <div className="text-right">
              <span className="text-secondary">Form: </span><RxDrugForm rxcui={prompt.rx_drug.rxcui} />
              <span className="text-secondary m-1">Dosage: </span><RxDrugDosage rxcui={prompt.rx_drug.rxcui} />
            </div>
            <div className="text-secondary">{dayjs(prompt.created_at).format('MMM D, YYYY h:mm A')}</div>
          </div>
        </td>
        <td>
          { !prompt.available && !prompt.unavailable &&
          <div className="d-flex align-items-center">
            <Button className="text-success me-2" tooltip="Yes" onClick={() => {
              confirm({
                title: "Yes, We have the drug!",
                icon: <CheckCircleOutlined />,
                content: 'Are you sure you have the drug in stock?',
                okText: 'Yes, We have',
                okType: 'primary',
                cancelText: 'Cancel',
                onOk() {
                  accept(prompt.id)
                },
              });
            }}>
              Yes
            </Button>
            <Button className="text-danger" onClick={() => {
              confirm({
                title: "No, We don't have the drug!",
                icon: <CloseCircleOutlined />,
                content: "Are you sure you don't have the drug in stock?",
                okText: "No, We don't have",
                okType: 'primary',
                cancelText: 'Cancel',
                onOk() {
                  decline(prompt.id)
                },
              });
            }}>
              No
            </Button>
          </div>
          }
        </td>
      </tr>
  );
};

export default DrugPrompt;
