import React, {useEffect, useState} from 'react'
import DoctorSidebar from '../sidebar'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { doctordashboardprofile01, doctordashboardprofile02, doctordashboardprofile06, doctordashboardprofile3 } from '../../imagepath'
import Header from '../../header'
import DoctorFooter from '../../common/doctorFooter'
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import api from '../../../api';
import dayjs from "dayjs";
import {Table, Switch} from "antd";
import {RxDrugDosage, RxDrugForm, RxDrugName} from "../../common/drug";

const DoctorMedications = (props) => {
    const authUser = useAuthUser();
    const [presetMedications, setPresetMedications] = useState([]);

    useEffect(() => {
        fetchPharmacyPresetDrugs();
    }, []);

    const fetchPharmacyPresetDrugs = () => {
        api.get(`/pharmacies/${authUser.pharmacy.id}/pharmacy_preset_drugs.json`)
            .then((res) => {
                console.log(res.data)
                if (res.data) {
                    const data = res.data.map((presetDrug) => {
                        return {
                            id: presetDrug.id,
                            name: presetDrug.rx_drug.name,
                            psn: presetDrug.rx_drug.psn,
                            medication: presetDrug.rx_drug.rxcui,
                            available: presetDrug.available,
                        }
                    });
                    setPresetMedications(data)
                }
            })
            .catch(err => console.log(err));
    }

    const togglePresetDrugAvailable = (preset_id, available) => {
        api.put(`/pharmacy_preset_drugs/${preset_id}.json`, {available: available})
            .then((res) => {
                console.log(res.data)
                if (res.data) {
                    const presetDrugs = presetMedications.map((medication) => medication.id === res.data.id ? {...medication, available: res.data.available} : medication)
                    setPresetMedications(presetDrugs)
                }
            })
            .catch(err => console.log(err));
    }

    const presetDrugColumns = [
        {
            title: 'Drug ID',
            key: 'id',
            dataIndex: 'medication',
            render: (rxcui) => `#${rxcui}`,
        },
        {
            title: 'General Name',
            key: 'generalName',
            dataIndex: 'name',
            sorter: (a, b) => (a.name ? (b.name ? a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }) : 1) : -1),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Drug Name',
            key: 'drugName',
            dataIndex: 'medication',
            render: (rxcui) => (<RxDrugName rxcui={rxcui} />),
        },
        {
            title: 'Drug Form',
            key: 'drugForm',
            dataIndex: 'medication',
            render: (rxcui) => (<RxDrugForm rxcui={rxcui} />),
        },
        {
            title: 'Dosage',
            key: 'dosage',
            dataIndex: 'medication',
            render: (rxcui) => (<RxDrugDosage rxcui={rxcui} />),
        },
        {
            title: 'Available',
            key: 'available',
            render: (_, record) => (
                <>
                    <Switch checkedChildren="Yes" unCheckedChildren="No" checked={record.available} onChange={(value) => togglePresetDrugAvailable(record.id, value)} />
                </>
            ),
        },
    ];

    return (
        <div className="col-lg-8 col-xl-9">
            <div className="dashboard-header">
                <h3>Medications In Short Supply</h3>
            </div>
            <Table columns={presetDrugColumns} dataSource={presetMedications} />
        </div>
    )
}

export default DoctorMedications
