import React from "react";
import DashboardSidebar from "../sidebar/sidebar.jsx";
import IMG01 from "../../../../app/assets/images/patient.jpg";
import StickyBox from "react-sticky-box";
import { Link } from "react-router-dom";
import Footer from "../../../footer.jsx";
import Header from "../../../header.jsx";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const Profile = (props) => {
  const authUser = useAuthUser();

  return (
    <div className="col-lg-8 col-xl-9">
                <form >
                  <div className="setting-card">
                    <div className="change-avatar img-upload">
                      <div className="profile-img">
                        <i className="fa-solid fa-file-image" />
                      </div>
                      <div className="upload-img">
                        <h5>Profile Image</h5>
                        <div className="imgs-load d-flex align-items-center">
                          <div className="change-photo">
                            Upload New
                            <input type="file" className="upload" />
                          </div>
                          <Link to="#" className="upload-remove">
                            Remove
                          </Link>
                        </div>
                        <p className="form-text">
                          Your Image should Below 4 MB, Accepted format
                          jpg,png,svg
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="setting-title">
                    <h5>Information</h5>
                  </div>
                  <div className="setting-card">
                    <div className="row">
                      <div className="col-lg-4 col-md-6">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" value={authUser.patient.first_name} />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control"  value={authUser.patient.last_name}/>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Date of Birth <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" value={authUser.patient.birthdate} />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Phone Number <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" value={'+' + authUser.patient.phone} />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Email Address <span className="text-danger">*</span>
                          </label>
                          <input type="email" className="form-control" value={authUser.email} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="setting-title">
                    <h5>Address</h5>
                  </div>
                  <div className="setting-card">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Address <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" value={authUser.patient.address.street} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            City <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control"  value={authUser.patient.address.city}/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            State <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control"  value={authUser.patient.address.state}/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Country <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control"  value={"United States"}/>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-wrap">
                          <label className="col-form-label">
                            Zipcode <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control"  value={authUser.patient.address.zip_code}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-btn text-end">
                    <Link to="#" className="btn btn-gray" disabled>
                      Cancel
                    </Link>
                    <button type="submit" className="btn btn-primary prime-btn" disabled>
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
  );
};

export default Profile;
