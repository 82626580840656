import React, { useState } from "react";
import AppContainer from "./appcontainer.jsx";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import config from 'config';
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit';
//
// require("./app/assets/icons/fontawesome/css/fontawesome.min.css");
// require("./app/assets/icons/fontawesome/css/all.min.css");
// require("./app/assets/icons/feather/css/iconfont.css");
// require("./app/assets/scss/main.scss");
// require("./components/customstyleclient.css");

const store = createStore({
  authName:'_auth',
  authType:'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:',
});

const AppRouter = () => {
  return (
    <AuthProvider store={store}>
      <Router basename={`/`}>
        <Route render={(props) => <AppContainer {...props} />} />
      </Router>
    </AuthProvider>
  );
};

export default AppRouter;
