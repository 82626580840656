import React, { useState } from "react";
import { Button } from "antd";
import {Link, useHistory} from "react-router-dom";

const NavLinks = () => {
  const history = useHistory();
  const [menu, setMenu] = useState(false);

  let pathnames = window.location.pathname;

  return (
    <>
      <li
        className={`has-submenu megamenu  ${
          pathnames.includes("index")
            ? "active"
            : "" || pathnames.includes("home")
            ? "active"
            : ""
        }`}
      >
        {pathnames.includes("patients") &&
          <Button
            type={'primary'}
            size={'large'}
            onClick={() => history.push('/')}
            className={`${menu === true ? "submenu " : ""}`}
          >
            Find a Pharmacy
          </Button>
        }
      </li>
    </>
  );
};

export default NavLinks;
