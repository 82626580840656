import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Header from "../../header";
import Footer from "../../footer";
import FeatherIcon from "feather-icons-react";
import DoctorSidebar from "../../doctors/sidebar";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import DashboardSidebar from "../../patients/dashboard/sidebar/sidebar";
import {Button, Form, Input, Space, Table, Tag, Typography} from "antd";
import {SendOutlined} from "@ant-design/icons";
import api from '../../../api';
import dayjs from "dayjs";
import {RxDrugDosage, RxDrugName} from "../../common/drug";

const Contactus = (props) => {
  const authUser = useAuthUser();
  const history = useHistory();
  const [form] = Form.useForm();
  const [supportConversations, setSupportConversations] = useState([]);

  const onFinish = (values) => {
    // create new conversation and initial message from user to customer support
    api.post('/support_conversations.json', {
      support_conversation: {
        user_id: authUser.id,
        topic: values.topic,
     }})
    .then(async (res) => {
      if (res.data) {
        console.log(res.data)
        setSupportConversations([...supportConversations, res.data])

        api.post('/support_messages.json', {
          support_message: {
            support_conversation_id: res.data.id,
            user_id: authUser.id,
            content: values.message,
        }})
        .then(async (res) => {
          if (res.data) {
            form.resetFields();
            console.log(res.data)
          }
        })
        .catch(err => console.log(err));
        console.log(res.data)
      }
    })
    .catch(err => console.log(err));
  }

  useEffect(() => {
    fetchSupportConversations();
  }, []);

  const fetchSupportConversations = () => {
    api.get('/support_conversations.json')
        .then((res) => {
          console.log(res.data)
          if (res.data) {
            const data = res.data.filter((conv) => conv.user_id === authUser.id || authUser.role === 'staff')
            setSupportConversations(data)
          }
          // setNameOptionsLoading(false);
        })
        .catch(err => console.log(err));
  }

  const columns = [
    {
      title: 'Support ID#',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (data) => dayjs(data.created_at).format('YYYY-MM-DD')
    },
    {
      title: 'Subject',
      dataIndex: 'topic',
      key: 'topic',
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      render: (data) => <Button type="link" onClick={() => history.push(`/accounts/users/support-conversations/${data.id}`)} >View Messages</Button>,
    },
  ];

  return (
    <div className="col-lg-8 col-xl-9">
      <div className="card w-100">
        <div className="card-body">
          <Table columns={columns} dataSource={supportConversations} />

          <Typography.Title level={5}>Submit New Question</Typography.Title>
          <Form form={form} name="horizontal_login" layout="vertical" onFinish={onFinish}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <Form.Item
                      label="Subject"
                      name="topic"
                      rules={[{ required: true, message: 'Please input subject.' }]}
                  >
                    <Input size="large" name="topic" placeholder="Enter Subject" />
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <Form.Item
                      label="Message"
                      name="message"
                      rules={[{ required: true, message: 'Please input message text.' }]}
                  >
                    <Input.TextArea rows={3} placeholder="Type and send your message here." />
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-0">
                  <Form.Item shouldUpdate>
                    {() => (
                        <Button
                            size={"large"}
                            htmlType="submit"
                            disabled={
                              !form.isFieldsTouched(true) ||
                              !!form.getFieldsError().filter(({ errors }) => errors.length).length
                            }
                        >
                          Send Message
                        </Button>
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
