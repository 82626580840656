import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/img/imagewithbasebath";
import { logo } from "../../imagepath";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const Home2Header = () => {
  const authUser = useAuthUser();

  const [headerClass, setHeaderClass] = useState(
    "header header-trans header-two"
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 100;

      if (scrollPosition > scrollThreshold) {
        setHeaderClass("header header-trans header-two pharmacy-header");
      } else {
        setHeaderClass("header header-trans header-two");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {/* Header */}
      <header className={headerClass}>
        <div className="container">
          <nav className="navbar navbar-expand-lg header-nav">
            <div className="navbar-header">
              <Link id="mobile_btn" to="#">
                <span className="bar-icon">
                  <span />
                  <span />
                  <span />
                </span>
              </Link>
              <Link to="/" className="navbar-brand logo">
                <img
                  src={logo}
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to="/" className="menu-logo">
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="Logo"
                  />
                </Link>
                <Link id="menu_close" className="menu-close" to="#">
                  <i className="fas fa-times" />
                </Link>
              </div>
              {/*<ul className="main-nav">*/}
              {/*  <li className="has-submenu megamenu active">*/}
              {/*    <Link to="/">*/}
              {/*      Find a Pharmacy*/}
              {/*    </Link>*/}
              {/*  </li>*/}
              {/*  <li className="login-link">*/}
              {/*    <Link to="/login">Login / Signup</Link>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </div>
            <ul className="nav header-navbar-rht">
              <li className="nav-item">
                <Link className="nav-link header-login" to="/login">
                  login / Signup{" "}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      {/* /Header */}
    </>
  );
};

export default Home2Header;
