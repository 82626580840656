import React, {useEffect, useState} from 'react'
import Header from '../../header'
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import {Tag, Table} from "antd";
import { doctor_thumb_01, doctor_thumb_02, doctor_thumb_03, doctor_thumb_05, doctor_thumb_07, doctor_thumb_08, doctor_thumb_09, logo } from '../../imagepath';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

import Footer from '../../footer';
import DashboardSidebar from '../dashboard/sidebar/sidebar';
import api from '../../../api';
import {RxDrugDosage, RxDrugName} from "../../common/drug";

const PatientInvoice = (props) => {
  const authUser = useAuthUser();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = () => {
    api.get('/rx_search_requests.json')
        .then((res) => {
          if (res.data) {
            const data = res.data.filter((order) => order.patient_id === authUser.patient.id && order.rx_drug && order.rx_drug.rxcui).map((order) => {

              return {
                key: order.id,
                id: order.id,
                orderDate: dayjs(order.created_at).format('YYYY-MM-DD'),
                drugName: order.rx_drug.rxcui,
                dosage: order.rx_drug.rxcui,
                pharmacy: order.rx_pharmacy_name,
                status: 'requested',
              }
            });
            setOrders(data)
          }
          // setNameOptionsLoading(false);
        })
        .catch(err => console.log(err));
  }

  const columns = [
    {
      title: 'Order ID#',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Order Date',
      dataIndex: 'orderDate',
      key: 'date',
    },
    {
      title: 'Drug Name',
      dataIndex: 'drugName',
      key: 'drugName',
      render: (rxcui) => (<RxDrugName rxcui={rxcui} />),
    },
    {
      title: 'Dosage',
      key: 'dosage',
      dataIndex: 'dosage',
      render: (rxcui) => (<RxDrugDosage rxcui={rxcui} />),
    },
    {
      title: 'Fulfilling Pharmacy',
      key: 'pharmacy',
      dataIndex: 'pharmacy',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (status) => (
          <>
            <Tag color={'green'} key={status}>
              {status.toUpperCase()}
            </Tag>
          </>
      ),
    },
  ];


  return (
    <>
    <div className='main-wrapper'>
      <Header {...props} />
      {/* Breadcrumb */}
      <div className="breadcrumb-bar-two">
        <div className="container">
          <div className="row align-items-center inner-banner">
            <div className="col-md-12 col-12 text-center">
              <h2 className="breadcrumb-title">Orders</h2>
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    Orders
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      {/* Page Content */}
      <div className="content doctor-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-xl-3 theiaStickySidebar">
              {/* Profile Sidebar */}
             <DashboardSidebar/>
              {/* /Profile Sidebar */}
            </div>
            {/* Orders */}
            <div className="col-lg-8 col-xl-9">
              <div className="dashboard-header">
                <h3>Orders</h3>
              </div>
              <div className="search-header">
                <div className="search-field">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <span className="search-icon">
                    <i className="fa-solid fa-magnifying-glass" />
                  </span>
                </div>
              </div>
              <div className="custom-table">
                <div className="table-responsive">
                  <Table columns={columns} dataSource={orders} />
                </div>
              </div>
            </div>
            {/* /Orders */}
          </div>
        </div>
      </div>
      {/* /Page Content */}
      
<Footer {...props} />

    </div>
     {/*View Invoice */}
  <div className="modal fade custom-modals" id="invoice_view">
  <div
    className="modal-dialog modal-dialog-centered modal-lg"
    role="document"
  >
    <div className="modal-content">
      <div className="modal-header">
        <h3 className="modal-title">View Invoice</h3>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i className="fa-solid fa-xmark" />
        </button>
      </div>
      <div className="modal-body pb-0">
        <div className="prescribe-download">
          <h5>21 Mar 2024</h5>
          <ul>
            <li>
              <Link to="#" className="print-link">
                <i className="fa-solid fa-print" />
             </Link>
            </li>
            <li>
              <Link to="#" className="btn btn-primary prime-btn">
                Download
             </Link>
            </li>
          </ul>
        </div>
        <div className="view-prescribe invoice-content">
          <div className="invoice-item">
            <div className="row">
              <div className="col-md-6">
                <div className="invoice-logo">
                  <img src={logo} alt="logo" />
                </div>
              </div>
              <div className="col-md-6">
                <p className="invoice-details">
                  <strong>Invoice No : </strong> #INV005
                  <br />
                  <strong>Issued:</strong> 21 Mar 2024
                </p>
              </div>
            </div>
          </div>
          {/* Invoice Item */}
          <div className="invoice-item">
            <div className="row">
              <div className="col-md-4">
                <div className="invoice-info">
                  <h6 className="customer-text">Billing From</h6>
                  <p className="invoice-details invoice-details-two">
                    Edalin Hendry <br />
                    806 Twin Willow Lane, <br />
                    Newyork, USA <br />
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="invoice-info">
                  <h6 className="customer-text">Billing To</h6>
                  <p className="invoice-details invoice-details-two">
                    Richard Wilson <br />
                    299 Star Trek Drive
                    <br />
                    Florida, 32405, USA
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="invoice-info invoice-info2">
                  <h6 className="customer-text">Payment Method</h6>
                  <p className="invoice-details">
                    Debit Card <br />
                    XXXXXXXXXXXX-2541
                    <br />
                    HDFC Bank
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* /Invoice Item */}
          {/* Invoice Item */}
          <div className="invoice-item invoice-table-wrap">
            <div className="row">
              <div className="col-md-12">
                <h6>Invoice Details</h6>
                <div className="table-responsive">
                  <table className="invoice-table table table-bordered">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Quatity</th>
                        <th>VAT</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>General Consultation</td>
                        <td>1</td>
                        <td>$0</td>
                        <td>$150</td>
                      </tr>
                      <tr>
                        <td>Video Call</td>
                        <td>1</td>
                        <td>$0</td>
                        <td>$100</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 ms-auto">
                <div className="table-responsive">
                  <table className="invoice-table-two table">
                    <tbody>
                      <tr>
                        <th>Subtotal:</th>
                        <td>
                          <span>$350</span>
                        </td>
                      </tr>
                      <tr>
                        <th>Discount:</th>
                        <td>
                          <span>-10%</span>
                        </td>
                      </tr>
                      <tr>
                        <th>Total Amount:</th>
                        <td>
                          <span>$315</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* /Invoice Item */}
          {/* Invoice Information */}
          <div className="other-info mb-0">
            <h4>Other information</h4>
            <p className="text-muted mb-0">
              An account of the present illness, which includes the
              circumstances surrounding the onset of recent health changes and
              the chronology of subsequent events that have led the patient to
              seek medicine
            </p>
          </div>
          {/* /Invoice Information */}
        </div>
      </div>
    </div>
  </div>
</div>

{/* /View Invoice */}
</>
  )
}

export default  PatientInvoice