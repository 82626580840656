import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import loginBanner from "../../app/assets/images/login-banner.png";
import Header from "../header";
import Footer from "../footer";
import {Form, Input, Button, Alert} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import axios from "axios";

const Register = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const signIn = useSignIn();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const onSubmit = (values) => {
    setLoading(true);
    axios.post('/users/signup.json', {user: {...values, role: "patient"}})
        .then((res)=>{
          console.log(res);
          if(res.status === 200){
            if(signIn({
              auth: {
                token: res.data.token,
                type: 'Bearer'
              },
              refresh: res.data.refreshToken,
              userState: res.data.authUserState
            })){ // Only if you are using refreshToken feature
              // Redirect or do-something
            }else {
              //Throw error
            }
          }
          setLoading(false);
          history.push('/login');
        })
        .catch((error) => {
          setErrors("Email address is already been taken.");
          setLoading(false);
          console.log(error)
        })
  }

  useEffect(() => {
    document.body.classList.add("account-page");

    return () => document.body.classList.remove("account-page");
  }, []);

  return (
    <>
      <Header {...props} />

      <>
        {/* Breadcrumb */}
        <div className="breadcrumb-bar-two">
          <div className="container">
            <div className="row align-items-center inner-banner">
              <div className="col-md-12 col-12 text-center">
                <h2 className="breadcrumb-title">Signup</h2>
                {/*<nav aria-label="breadcrumb" className="page-breadcrumb">*/}
                {/*  <ol className="breadcrumb">*/}
                {/*    <li className="breadcrumb-item">*/}
                {/*      <Link to="/">Home</Link>*/}
                {/*    </li>*/}
                {/*    <li className="breadcrumb-item" aria-current="page">*/}
                {/*      Dashboard*/}
                {/*    </li>*/}
                {/*  </ol>*/}
                {/*</nav>*/}
              </div>
            </div>
          </div>
        </div>
        {/* /Breadcrumb */}
        {/* Page Content */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                {/* Register Content */}
                <div className="account-content">
                  <div className="row align-items-center justify-content-center">
                    {/*<div className="col-md-7 col-lg-6 login-left">*/}
                    {/*  <img*/}
                    {/*    src={loginBanner}*/}
                    {/*    className="img-fluid"*/}
                    {/*    alt="Doccure Register"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className="col-md-12 col-lg-6 login-right">
                      <div className="login-header">
                        <h3>
                          Patient Register{" "}
                          <Link to="/pharmacy-register">
                            Are you a Pharmacist?
                          </Link>
                        </h3>
                      </div>
                      {/* Register Form */}
                      <Form
                          layout={'horizontal'}
                          form={form}
                          name="register"
                          onFinish={onSubmit}
                          style={{ maxWidth: 600 }}
                      >
                        {/*<div className="form-group form-focus">*/}
                        {/*  <Form.Item name="first_name" rules={[{ required: true, message: "First name is required" }]}*/}
                        {/*  >*/}
                        {/*    <Input*/}
                        {/*        type="text"*/}
                        {/*        className="form-control"*/}
                        {/*        placeholder="First name"*/}
                        {/*    />*/}
                        {/*  </Form.Item>*/}
                        {/*</div>*/}
                        {/*<div className="form-group form-focus">*/}
                        {/*  <Form.Item name="last_name" rules={[{ required: true, message: "Last name is required" }]}*/}
                        {/*  >*/}
                        {/*    <Input*/}
                        {/*        type="text"*/}
                        {/*        className="form-control"*/}
                        {/*        placeholder="Last name"*/}
                        {/*    />*/}
                        {/*  </Form.Item>*/}
                        {/*</div>*/}
                        <div className="form-group">
                          <Form.Item name="email" rules={[{ required: true, message: "Email address is required" }]}
                          >
                            <Input
                                size="large"
                                type="email"
                                placeholder="Email address"
                            />
                          </Form.Item>
                        </div>
                        <div className="form-group">
                          <Form.Item name="password" rules={[{ required: true, message: "Password is required" }]}
                          >
                            <Input
                                size="large"
                                type="password"
                                placeholder="Password"
                            />
                          </Form.Item>
                        </div>
                        <div className="text-end">
                          <Link
                              className="forgot-link"
                              to="/login"
                          >
                            Already have an account?
                          </Link>
                        </div>
                        {errors &&
                        <Alert
                            message="Error"
                            description={errors}
                            type="error"
                            showIcon
                            className="mb-3"
                        />
                        }
                        <Button
                            size={"large"}
                            type="primary" htmlType="submit" className="w-100 login-btn"
                            icon={loading ? <LoadingOutlined /> : null}>
                          Signup
                        </Button>
                      </Form>
                      {/* /Register Form */}
                    </div>
                  </div>
                </div>
                {/* /Register Content */}
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </>

      <Footer {...props} />
    </>
  );
};

export default Register;
