/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../app/assets/images/logo.png";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import {footer3_logo} from "./imagepath";

const Footer = (props) => {
  //Aos

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  const exclusionArray = [
    "/pages/doctor-grid",
    "/pages/doctor-list",
    "/pages/video-call",
    "/pages/voice-call",
    "/pages/chat-doctor",
    "/patient/doctor-list",
    "/patient/doctor-grid",
  ];
  if (exclusionArray.indexOf(props.location.pathname) >= 0) {
    return "";
  }
  return (
      <>
        {/* Footer */}
        <footer className="footer">
          {/* Footer Top */}
          <div className="footer-top">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  {/* Footer Widget */}
                  <div className="footer-widget footer-about">
                    <div className="footer-logo">
                      <img src={footer3_logo} alt="logo"/>
                      {/*<ImageWithBasePath src="assets/img/footer-logo.png" />*/}
                    </div>
                    <div className="footer-about-content">
                      {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor*/}
                      {/*  incididunt ut labore et dolore magna aliqua. </p>*/}
                      <div className="social-icon">
                        <ul>
                          <li>
                            <Link to="#"><i className="fab fa-facebook-f" /> </Link>
                          </li>
                          <li>
                            <Link to="#"><i className="fab fa-twitter" /> </Link>
                          </li>
                          <li>
                            <Link to="#"><i className="fab fa-linkedin-in" /></Link>
                          </li>
                          <li>
                            <Link to="#"><i className="fab fa-instagram" /></Link>
                          </li>
                          <li>
                            <Link to="#"><i className="fab fa-dribbble" /> </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* /Footer Widget */}
                </div>
                <div className="col-lg-3 col-md-6">
                  {/* Footer Widget */}
                  <div className="footer-widget footer-menu">
                    <h2 className="footer-title">Quick Links</h2>
                    <ul>
                      <li><Link to="/login">Login</Link></li>
                      <li><Link to="/register">Register</Link></li>
                      <li><Link to="/">Find a Pharmacy</Link></li>
                      <li><Link to="/accounts/pharmacies/dashboard">Pharmacist Dashboard</Link></li>
                    </ul>
                  </div>
                  {/* /Footer Widget */}
                </div>
                <div className="col-lg-4 col-md-6">
                  {/* Footer Widget */}
                  <div className="footer-widget footer-contact">
                    <h2 className="footer-title">Contact Us</h2>
                    <div className="footer-contact-info">
                      {/*<div className="footer-address">*/}
                      {/*  <span><i className="fas fa-map-marker-alt" /></span>*/}
                      {/*  <p> 3556 Beech Street, San Francisco,<br /> California, CA 94108 </p>*/}
                      {/*</div>*/}
                      <p>
                        <i className="fas fa-phone-alt" />
                        Direct: 000 124 3625
                      </p>
                      <p className="mb-0">
                        <i className="fas fa-envelope" />
                        admin@prescriptionsquest.com
                      </p>
                    </div>
                  </div>
                  {/* /Footer Widget */}
                </div>
              </div>
            </div>
          </div>
          {/* /Footer Top */}
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="container-fluid">
              {/* Copyright */}
              <div className="copyright">
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <div className="copyright-text">
                      <p className="mb-0">© Copyright 2024 all rights reserved</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6">
                    {/* Copyright Menu */}
                    <div className="copyright-menu">
                      <ul className="policy-menu">
                        <li><Link to="#">Terms of Use</Link></li>
                        <li><Link to="#">Data Privacy</Link></li>
                        <li><Link to="#">HIPAA Privacy Notice</Link></li>
                      </ul>
                    </div>
                    {/* /Copyright Menu */}
                  </div>
                </div>
              </div>
              {/* /Copyright */}
            </div>
          </div>
          {/* /Footer Bottom */}
        </footer>
        {/* /Footer */}
      </>
  );
};

export default Footer;
