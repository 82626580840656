import React, { useState } from "react";
import Header from "../../header";
import DoctorSidebar from "../sidebar/index";
import DoctorFooter from "../../common/doctorFooter";
import { Link } from "react-router-dom";

const Password = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
const [showConfirmPassword , setConfirmPassword] = useState(false);
const toggleConfirmPassword = () => {
  setConfirmPassword(!showConfirmPassword);
}
  return (
    <div className="col-lg-8 col-xl-9">
      <div className="dashboard-header">
        <h3>Change Password</h3>
      </div>
      <form>
        <div className="card pass-card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <div className="input-block input-block-new">
                  <label className="form-label">Old Password</label>
                  <input type="password" className="form-control" />
                </div>
                <div className="input-block input-block-new">
                  <label className="form-label">New Password</label>
                  <div className="pass-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control pass-input"
                    />
                    <span
                      className={`feather-eye${showPassword ? "" : "-off"} toggle-password`}
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                </div>
                <div className="input-block input-block-new mb-0">
                  <label className="form-label">Confirm Password</label>
                  <div className="pass-group">
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      className="form-control pass-input-sub"
                    />

                    <span
                    className={`feather-eye${showConfirmPassword ? "" : "-off"} toggle-password`}
                    onClick={toggleConfirmPassword}
                  />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-set-button">
          <button className="btn btn-light" type="button">
            Cancel
          </button>
          <Link className="btn btn-primary" to="#">
            Save Changes
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Password;
